export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: true,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    filterable: true,
    sortable: true,
  },
  {
    key: 'type',
    type: 'text',
    label: 'Тип',
    filterable: true,
    sortable: true,
  },
  {
    key: 'subject',
    type: 'text',
    label: 'Контент',
    filterable: false,
    sortable: false,
  },
  {
    key: 'status',
    type: 'text',
    label: 'Статус',
    filterable: false,
    sortable: true,
  },
  {
    key: 'from_name',
    type: 'text',
    label: 'От кого',
    filterable: false,
    sortable: true,
  },
  {
    key: 'schedule',
    type: 'text',
    label: 'Расписание',
    filterable: false,
    sortable: false,
  },
  // {
  //   key: 'params',
  //   type: 'text',
  //   label: 'Параметры',
  //   filterable: false,
  //   sortable: false,
  // },
  {
    key: 'selectionId',
    type: 'select',
    label: 'Получатели',
    sortable: false,
    filterable: false,
  },
  {
    key: 'updatedAt',
    type: 'datetime',
    label: 'Даты',
    filterable: false,
    sortable: true,
  },
];
