import agent from './agent';
import agentServiceFees from './agentServiceFees';
import attendanceByHalls from './attendanceByHalls';
import attendanceByMovie from './attendanceByMovie';
import attendanceByMovieWithUnused from './attendanceByMovieWithUnused';
import averaging from './averaging';
import cashier from './cashier';
import cashierSummary from './cashierSummary';
import certificates from './certificates';
import certificatesOld from './certificatesOld';
import certificatesSales from './certificatesSales';
import consolidatedSales from './consolidatedSales';
import consolidatedSalesAccounting from './consolidatedSalesAccounting';
import consolidatedSalesManagerial from './consolidatedSalesManagerial';
import consolidatedSalesOnMovie from './consolidatedSalesOnMovie';
import consolidatedSalesOwner from './consolidatedSalesOwner';
import dailyShows from './dailyShows';
import discountsByPeriod from './discountsByPeriod';
import distributor from './distributor';
import distributor5 from './distributor5';
import distributorAttendance from './distributorAttendance';
import favoriteMovies from './favoriteMovies';
import iikoSales from './iikoSales';
import kiosksDetailed from './kiosksDetailed';
import networkSalesByOnlineGroups from './networkSalesByOnlineGroups';
import occupancy from './occupancy';
import priceRatesUsage from './priceRatesUsage';
import proceedsByCinemas from './proceedsByCinemas';
import proceedsByCinemasByOnline from './proceedsByCinemasByOnline';
import proceedsByCinemasByOnlineWithDetails from './proceedsByCinemasByOnlineWithDetails';
import proceedsByCinemasByPc from './proceedsByCinemasByPc';
import proceedsByCinemasForDistribution from './proceedsByCinemasForDistribution';
import proceedsByMovies from './proceedsByMovies';
import promocodeGroupsByPeriod from './promocodeGroupsByPeriod';
import promocodes from './promocodes';
import rao from './rao';
import refunds from './refunds';
import releaseRentalPeriodFinish from './releaseRentalPeriodFinish';
import salesByApps from './salesByApps';
import salesByGroup from './salesByGroup';
import salesByHalls from './salesByHalls';
import serviceFees from './serviceFees';
import showSales from './showSales';

export default {
  namespaced: true,

  modules: {
    agent,
    agentServiceFees,
    attendanceByHalls,
    attendanceByMovie,
    attendanceByMovieWithUnused,
    averaging,
    cashier,
    cashierSummary,
    certificates,
    certificatesOld,
    certificatesSales,
    consolidatedSales,
    consolidatedSalesAccounting,
    consolidatedSalesManagerial,
    consolidatedSalesOnMovie,
    consolidatedSalesOwner,
    dailyShows,
    discountsByPeriod,
    distributor,
    distributor5,
    distributorAttendance,
    favoriteMovies,
    iikoSales,
    kiosksDetailed,
    networkSalesByOnlineGroups,
    occupancy,
    priceRatesUsage,
    proceedsByCinemas,
    proceedsByCinemasByOnline,
    proceedsByCinemasByOnlineWithDetails,
    proceedsByCinemasByPc,
    proceedsByCinemasForDistribution,
    proceedsByMovies,
    promocodeGroupsByPeriod,
    promocodes,
    rao,
    refunds,
    releaseRentalPeriodFinish,
    salesByApps,
    salesByGroup,
    salesByHalls,
    serviceFees,
    showSales,
  },
};
