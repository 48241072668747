<template>
  <div class="table-container legalEntities">
    <collection-provider
      collection="legalEntities"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <dictionary-provider name="taxSystems">
          <template #default="{ items: taxSystems }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :panel-actions="datatable.panelActions"
              :item-actions="datatable.itemActions"
              :checked="datatable.checked"
              :permissions="$user.permissions"
              @change-checked="datatable.checked = $event"
              @panel-action="performPanelAction($event)"
              @item-action="performItemAction($event)"
            >
              <template #filter(taxSystemId)>
                <b-form-select
                  size="sm"
                  :value="data.filter.taxSystemId"
                  :options="$array.convertToOptions(taxSystems)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('taxSystemId', $event)"
                ></b-form-select>
              </template>
              <template #cell(taxSystemId)="data">
                {{ $array.getItemText(taxSystems, data.item.taxSystemId) }}
              </template>
              <template #cell(vatType)="data">
                {{ $array.getItemText(vatTypes, data.item.vatType) }}
              </template>
            </datatable>
          </template>
        </dictionary-provider>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.entity.show"
      create-title="Создание юридического лица"
      edit-title="Редактирование юридического лица"
      collection="legalEntities"
      :id="modals.entity.id"
      :init="{ vatType: VAT_TYPE_NOMENCLATURE }"
      @close="modals.entity.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <entity-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></entity-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import { VAT_TYPE_NOMENCLATURE } from '../../constants';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';
import vatTypes from './_vatTypes';

import Datatable from '../../components/datatable/Datatable.vue';

import DictionaryProvider from '../../providers/DictionaryProvider';
import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import EntityForm from './EntityForm.vue';

export default {
  components: {
    DictionaryProvider,
    CollectionProvider,
    ModalModelForm,
    Datatable,
    EntityForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        entity: {
          show: false,
          id: 0,
        },
      },
      taxSystems: [],
      VAT_TYPE_NOMENCLATURE,
      vatTypes,
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateLegalEntityForm() {
      this.modals.entity.id = '';
      this.modals.entity.show = true;
    },

    async removeCheckedLegalEntities() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    openEditLegalEntityForm(id) {
      this.modals.entity.id = id;
      this.modals.entity.show = true;
    },

    async removeLegalEntity(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>
