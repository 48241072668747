<template>
  <div class="pt-3">
    <template v-if="kkmFiscalItems.length">
      <h5 class="mb-2 font-weight-normal">Информация по заказу</h5>
      <b-table
        class="mb-0"
        caption-top
        empty-text="Нет данных"
        :items="kkmFiscalItems"
        :fields="fields"
        :show-empty="true"
      >
        <template #cell(createdAt)="data">
          <div v-if="!!data.item.createdAt">
            {{ $datetime.format(data.item.createdAt, $datetime.datetimeFormat) }}
          </div>
          <div v-else>—</div>
        </template>

        <template #cell(operation)="data">
          <div v-if="data.item.operation === FISCAL_OPERATION_SALE">Продажа</div>
          <div v-if="data.item.operation === FISCAL_OPERATION_REFUND">Возврат</div>
        </template>

        <template #cell(itemType)="data">
          <div>{{ formatItemType(data.item) }}</div>
        </template>

        <template #cell(result)="data">
          <div v-if="data.item.receiptUrl"><a target="_blank" :href="getOfdUrl(data.item.receiptUrl)">Чек</a></div>
          <div v-else>—</div>
        </template>
      </b-table>
    </template>

    <template v-if="kkmFullFiscalItems.length">
      <h5 class="mt-3 mb-2 font-weight-normal">Зачет предоплаты</h5>
      <b-table
        class="mb-0"
        caption-top
        empty-text="Нет данных"
        :items="kkmFullFiscalItems"
        :fields="fields"
        :show-empty="true"
      >
        <template #cell(createdAt)="data">
          <div v-if="!!data.item.createdAt">
            {{ $datetime.format(data.item.createdAt, $datetime.datetimeFormat) }}
          </div>
          <div v-else>—</div>
        </template>

        <template #cell(operation)="data">
          <div v-if="data.item.operation">{{ data.item.operation }}</div>
          <div v-else>—</div>
        </template>

        <template #cell(itemType)="data">
          <div>{{ formatItemType(data.item) }}</div>
        </template>

        <template #cell(result)="data">
          <div v-if="data.item.receiptUrl" class="word-break-all">{{ data.item.receiptUrl }}</div>
          <div v-else>—</div>
        </template>
      </b-table>
    </template>
  </div>
</template>

<script>
import {
  FISCAL_OPERATION_SALE,
  FISCAL_OPERATION_REFUND,
  FISCAL_ITEM_TYPE_TICKET,
  FISCAL_ITEM_TYPE_PRODUCT,
  FISCAL_ITEM_TYPE_CERTIFICATE,
  FISCAL_ITEM_TYPE_SERVICE_FEE,
} from '../../constants';

import fields from './_fiscal.fields';

export default {
  props: {
    kkmFiscalItems: {
      default: [],
    },
    kkmFullFiscalItems: {
      default: [],
    },
  },
  data() {
    return {
      fields,
      FISCAL_OPERATION_SALE,
      FISCAL_OPERATION_REFUND,
    };
  },
  methods: {
    formatItemType(item) {
      if (item.itemType === FISCAL_ITEM_TYPE_TICKET) {
        return `Билет ${item.itemId}`;
      }

      if (item.itemType === FISCAL_ITEM_TYPE_PRODUCT) {
        return 'Товар';
      }

      if (item.itemType === FISCAL_ITEM_TYPE_CERTIFICATE) {
        return 'Сертификат';
      }

      if (item.itemType === FISCAL_ITEM_TYPE_SERVICE_FEE) {
        return 'Сервисный сбор';
      }

      return '';
    },

    getOfdUrl(receiptUrl) {
      if (!receiptUrl) {
        return '';
      }

      return `https://lk.platformaofd.ru/web/noauth/cheque/search?${receiptUrl}`;
    },
  },
};
</script>

<style scoped>
.word-break-all {
  word-break: break-all;
}
.table >>> th {
  font-weight: 400;
  font-size: 14px;
}
.table >>> td {
  font-weight: 300;
  font-size: 14px;
}
</style>
