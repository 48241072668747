import AppGroups from '../pages/app-groups/Index.vue';
import Apps from '../pages/apps/Index.vue';
import Cinemas from '../pages/cinemas/Index.vue';
import Equipment from '../pages/equipment/Index.vue';
import Full from '../layout/Full.vue';
import Hall from '../pages/halls/Index.vue';
import HallCategories from '../pages/hall-categories/Index.vue';
import LegalEntities from '../pages/legal-entities/Index.vue';
import Library from '../pages/media-library/Index.vue';
import MailAccounts from '../pages/mail-accounts/Index.vue';
import MediaPanels from '../pages/media-panels/Index.vue';
import MediaPanelsLogs from '../pages/media-logs/Index.vue';
import MerchantAccounts from '../pages/merchant-accounts/Index.vue';
import ProductGroups from '../pages/product-groups/Index.vue';
import ProductReportGroups from '../pages/product-report-groups/Index.vue';
import Products from '../pages/products/Index.vue';
import Roles from '../pages/roles/Index.vue';
import Users from '../pages/users/Index.vue';

export default {
  path: '/administration',
  redirect: '/administration/cinemas',
  component: Full,
  children: [
    {
      name: 'legalEntities',
      path: 'legal-entities',
      component: LegalEntities,
      meta: {
        auth: true,
        title: 'Юридические лица',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Юридические лица' },
        ],
        scrollable: true,
        permissions: ['legal_entities.view'],
      },
    },
    {
      name: 'merchantAccounts',
      path: 'merchant-accounts',
      component: MerchantAccounts,
      meta: {
        auth: true,
        title: 'Мерчант аккаунты',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Мерчант аккаунты' },
        ],
        scrollable: true,
        permissions: ['merchant_accounts.view'],
      },
    },
    {
      name: 'mailAccounts',
      path: 'mail-accounts',
      component: MailAccounts,
      meta: {
        auth: true,
        title: 'Почтовые аккаунты',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Почтовые аккаунты' },
        ],
        scrollable: true,
        permissions: ['mail_accounts.view'],
      },
    },
    {
      name: 'cinemas',
      path: 'cinemas/:cinemaId?/:tab?',
      component: Cinemas,
      meta: {
        auth: true,
        title: 'Кинотеатр',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Кинотеатр' },
        ],
        permissions: [
          'cinemas.view',
          'halls.view',
          'employees.view',
          'cashier_devices.view',
          'cashier_places.view',
          'documents.view',
          'settings.view',
          'social_networks.view',
          'cinema_photos.view',
          'cinema_documents.view',
          'bar_settings.view',
        ],
      },
    },
    {
      name: 'createHall',
      path: 'cinemas/:cinemaId/halls/create',
      component: Hall,
      meta: {
        auth: true,
        title: 'Конструктор залов: новый зал',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Кинотеатры' },
          { name: '{cinema}, {city}', url: '/administration/cinemas/{cinemaId}' },
          { name: 'Залы', url: '/administration/cinemas/{cinemaId}/halls' },
          { name: 'Конструктор залов' },
        ],
        permissions: ['halls.edit'],
      },
    },
    {
      name: 'hall',
      path: 'cinemas/:cinemaId/halls/:id',
      component: Hall,
      props: true,
      meta: {
        auth: true,
        title: 'Конструктор залов: редактирование зала',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Кинотеатры' },
          { name: '{cinema}, {city}', url: '/administration/cinemas/{cinemaId}' },
          { name: 'Залы', url: '/administration/cinemas/{cinemaId}/halls' },
          { name: 'Конструктор залов' },
        ],
        permissions: ['halls.edit'],
      },
    },
    {
      name: 'hallCategories',
      path: 'hall-categories',
      component: HallCategories,
      meta: {
        auth: true,
        title: 'Категории залов',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Кинотеатры' },
          { name: 'Категории залов' },
        ],
        scrollable: true,
        permissions: ['hall_categories.view'],
      },
    },
    {
      name: 'apps',
      path: 'apps',
      component: Apps,
      meta: {
        auth: true,
        title: 'Приложения',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Внешние продажи' },
          { name: 'Приложения' },
        ],
        scrollable: true,
        permissions: ['apps.view'],
      },
    },
    {
      name: 'appGroups',
      path: 'app-groups',
      component: AppGroups,
      meta: {
        auth: true,
        title: 'Группы приложения',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Внешние продажи' },
          { name: 'Группы приложения' },
        ],
        scrollable: true,
        permissions: ['app_groups.view'],
      },
    },
    {
      name: 'users',
      path: 'users',
      component: Users,
      meta: {
        auth: true,
        title: 'Пользователи',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Сотрудники' },
          { name: 'Пользователи' },
        ],
        scrollable: true,
        permissions: ['users.view'],
      },
    },
    {
      name: 'roles',
      path: 'roles',
      component: Roles,
      meta: {
        auth: true,
        title: 'Роли',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Пользователи' },
          { name: 'Роли' },
        ],
        scrollable: true,
        permissions: ['roles.view'],
      },
    },
    {
      name: 'products',
      path: 'products/:cinemaId?',
      component: Products,
      meta: {
        auth: true,
        title: 'Товары',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Товары' },
          { name: 'Товары' },
        ],
        permissions: ['products.view'],
      },
    },
    {
      name: 'productGroups',
      path: 'product-groups/:cinemaId?',
      component: ProductGroups,
      meta: {
        auth: true,
        title: 'Группы товаров для отображения',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Товары' },
          { name: 'Группы товаров для отображения' },
        ],
        permissions: ['product_groups.view'],
      },
    },
    {
      name: 'productReportGroups',
      path: 'product-report-groups/:cinemaId?',
      component: ProductReportGroups,
      meta: {
        auth: true,
        title: 'Группы товаров для отчетов',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Товары' },
          { name: 'Группы товаров для отчетов' },
        ],
        permissions: ['product_report_groups.view'],
      },
    },
    {
      name: 'mediaPanels',
      path: 'media-panels/:cinemaId?',
      component: MediaPanels,
      meta: {
        auth: true,
        title: 'Медиапанели',
        breadcrumbs: [
          { name: 'Администрирование' },
        ],
        permissions: ['mediapanels.screens.view'],
      },
    },
    {
      name: 'mediaPanelsLogs',
      path: 'media-panels-logs/:cinemaId?',
      component: MediaPanelsLogs,
      meta: {
        auth: true,
        title: 'Логи',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Медиапанели' },
          { name: 'Логи' },
        ],
        permissions: ['mediapanels.logs.view'],
      },
    },
    {
      name: 'library',
      path: 'library/',
      component: Library,
      meta: {
        auth: true,
        title: 'Библиотека',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Медиапанели' },
          { name: 'Библиотека' },
        ],
        permissions: ['mediapanels.library.view'],
      },
    },
    {
      name: 'equipment',
      path: 'equipment/:cinemaId?',
      component: Equipment,
      meta: {
        auth: true,
        title: 'Оборудование',
        breadcrumbs: [
          { name: 'Администрирование' },
          { name: 'Оборудование' },
        ],
        permissions: ['equipment.view'],
      },
    },
  ],
};
