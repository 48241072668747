<template>
  <div class="promotion-min-price mt-2">
    Минимальная цена: {{ item.minPrice ? item.minPrice : 'Не задана'}}
  </div>
</template>

<script>
export default {
  props: {
    item: {
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.promotion-min-price {
  font-size: 12px;
}
</style>
