import {
  VAT_TYPE_NONE, VAT_TYPE_NOMENCLATURE, VAT_TYPE_SPECIAL_5, VAT_TYPE_SPECIAL_7,
} from '../../constants';

export default [
  {
    id: VAT_TYPE_NONE,
    name: 'Не работает с НДС',
  },
  {
    id: VAT_TYPE_NOMENCLATURE,
    name: 'НДС из настроек товара',
  },
  {
    id: VAT_TYPE_SPECIAL_5,
    name: 'НДС 5%',
  },
  {
    id: VAT_TYPE_SPECIAL_7,
    name: 'НДС 7%',
  },
];
