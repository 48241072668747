import Index from '../pages/reports/Index.vue';

import Agent from '../pages/reports/report-agent/Index.vue';
import AgentServiceFees from '../pages/reports/report-agent-service-fees/Index.vue';
import AttendanceByHalls from '../pages/reports/report-attendance-by-halls/Index.vue';
import AttendanceByMovie from '../pages/reports/report-attendance-by-movie/Index.vue';
import AttendanceByMovieWithUnused from '../pages/reports/report-attendance-by-movie-with-unused/Index.vue';
import Averaging from '../pages/reports/report-averaging/Index.vue';
import Cashier from '../pages/reports/report-cashier/Index.vue';
import CashierSummary from '../pages/reports/report-cashier-summary/Index.vue';
import Certificates from '../pages/reports/report-certificates/Index.vue';
import CertificatesOld from '../pages/reports/report-certificates-old/Index.vue';
import CertificatesSales from '../pages/reports/report-certificates-sales/Index.vue';
import ConsolidatedSales from '../pages/reports/report-consolidated-sales/Index.vue';
import ConsolidatedSalesAccounting from '../pages/reports/report-consolidated-sales-accounting/Index.vue';
import ConsolidatedSalesManagerial from '../pages/reports/report-consolidated-sales-managerial/Index.vue';
import ConsolidatedSalesOnMovie from '../pages/reports/report-consolidated-sales-on-movie/Index.vue';
import ConsolidatedSalesOwner from '../pages/reports/report-consolidated-sales-owner/Index.vue';
import DailyShows from '../pages/reports/report-daily-shows/Index.vue';
import DiscountsByPeriod from '../pages/reports/report-discounts-by-period/Index.vue';
import Distributor from '../pages/reports/report-distributor/Index.vue';
import Distributor5 from '../pages/reports/report-distributor5/Index.vue';
import DistributorAttendance from '../pages/reports/report-distributor-attendance/Index.vue';
import Eais from '../pages/reports/report-eais/Index.vue';
import FavoriteMovies from '../pages/reports/report-favorite-movies/Index.vue';
import IikoSales from '../pages/reports/report-iiko-sales/Index.vue';
import KiosksDetailed from '../pages/reports/report-kiosks-detailed/Index.vue';
import NetworkSalesByOnlineGroups from '../pages/reports/report-network-sales-by-online-groups/Index.vue';
import Occupancy from '../pages/reports/report-occupancy/Index.vue';
import PriceRatesUsage from '../pages/reports/report-price-rates-usage/Index.vue';
import ProceedsByCinemas from '../pages/reports/report-proceeds-by-cinemas/Index.vue';
import ProceedsByCinemasByOnline from '../pages/reports/report-proceeds-by-cinemas-by-online/Index.vue';
import ProceedsByCinemasByOnlineWithDetails from '../pages/reports/report-proceeds-by-cinemas-by-online-with-details/Index.vue';
import ProceedsByCinemasByPc from '../pages/reports/report-proceeds-by-cinemas-by-pc/Index.vue';
import ProceedsByCinemasForDistribution from '../pages/reports/report-proceeds-by-cinemas-for-distribution/Index.vue';
import ProceedsByMovies from '../pages/reports/report-proceeds-by-movies/Index.vue';
import PromocodeGroupsByPeriod from '../pages/reports/report-promocode-groups-by-period/Index.vue';
import Promocodes from '../pages/reports/report-promocodes/Index.vue';
import Rao from '../pages/reports/report-rao/Index.vue';
import Refunds from '../pages/reports/report-refunds/Index.vue';
import ReleaseRentalPeriodFinish from '../pages/reports/report-release-rental-period-finish/Index.vue';
import Rentrak from '../pages/reports/report-rentrak/Index.vue';
import SalesByApps from '../pages/reports/report-sales-by-apps/Index.vue';
import SalesByGroup from '../pages/reports/report-sales-by-group/Index.vue';
import SalesByHalls from '../pages/reports/report-sales-by-halls/Index.vue';
import ServiceFees from '../pages/reports/report-service-fees/Index.vue';
import ShowSales from '../pages/reports/report-show-sales/Index.vue';

import Full from '../layout/Full.vue';

export default {
  path: '/reports',
  redirect: '/reports',
  component: Full,
  children: [
    {
      name: 'reportIndex',
      path: '',
      component: Index,
      meta: {
        auth: true,
        scrollable: true,
        title: 'Отчетность',
        breadcrumbs: [{ name: 'Отчетность' }],
        permissions: [
          'reports.agent.view',
          'reports.agent_service_fees.view',
          'reports.attendance_by_hall.view',
          'reports.attendance_by_movie.view',
          'reports.attendance-by-movie-with-unused.view',
          'reports.cashier_summary.view',
          'reports.cashiers.view',
          'reports.certificates.view',
          'reports.certificates_sales.view',
          'reports.consolidated_sales.view',
          'reports.consolidated_sales_owner.view',
          'reports.daily_shows.view',
          'reports.discounts_by_period.view',
          'reports.distributors.view',
          'reports.eais.view',
          'reports.kiosks_detailed.view',
          'reports.proceeds_by_cinemas.view',
          'reports.proceeds_by_movies.view',
          'reports.rao.view',
          'reports.refunds_by_cinema.view',
          'reports.rentrak.view',
          'reports.sales_by_apps.view',
          'reports.sales_by_group.view',
          'reports.iiko_sales.view',
          'reports.show_sales.view',
          'reports.proceeds_by_cinemas_by_pc.view',
        ],
      },
    },
    {
      name: 'reportCashier',
      path: 'cashier',
      component: Cashier,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Кассовый отчет по продажам кассира',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.cashiers.view'],
      },
    },
    {
      name: 'reportCashierSummary',
      path: 'cashier-summary',
      component: CashierSummary,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Сводный отчет по продажам кассиров',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.cashier_summary.view'],
      },
    },
    {
      name: 'reportCertificatesOld',
      path: 'certificates-old',
      component: CertificatesOld,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по использованию сертификатов',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.certificates.view'],
      },
    },
    {
      name: 'reportCertificates',
      path: 'certificates',
      component: Certificates,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по использованию сертификатов (новый)',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.certificates.view'],
      },
    },
    {
      name: 'reportCertificatesSales',
      path: 'certificates-sales',
      component: CertificatesSales,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по продажам сертификатов',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.certificates_sales.view'],
      },
    },
    {
      name: 'reportDistributor',
      path: 'distributor',
      component: Distributor,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Дистрибьюторские отчеты',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.distributors.view'],
      },
    },
    {
      name: 'reportDistributor5',
      path: 'distributor5',
      component: Distributor5,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Дистрибьюторские отчеты (новые)',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.distributors.view'],
      },
    },
    {
      name: 'reportDailyShows',
      path: 'daily-shows',
      component: DailyShows,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Ежедневный посеансовый отчет',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.daily_shows.view'],
      },
    },
    {
      name: 'reportEais',
      path: 'eais',
      component: Eais,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчеты для ЕАИС',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.eais.view'],
      },
    },
    {
      name: 'reportRentrak',
      path: 'rentrak',
      component: Rentrak,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчеты для РЕНТРАК',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.rentrak.view'],
      },
    },
    {
      name: 'reportRao',
      path: 'rao',
      component: Rao,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчеты для РАО',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.rao.view'],
      },
    },
    {
      name: 'reportAttendanceByMovie',
      path: 'attendance-by-movie',
      component: AttendanceByMovie,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по посещаемости по релизам',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.attendance_by_movie.view'],
      },
    },
    {
      name: 'reportDistributorAttendance',
      path: 'distributor-attendance',
      component: DistributorAttendance,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по кинопрокатным компаниям',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.distributor_attendance.view'],
      },
    },
    {
      name: 'reportServiceFees',
      path: 'service-fees',
      component: ServiceFees,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по сервисным сборам',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.service_fees.view'],
      },
    },
    {
      name: 'reportAttendanceByMovieWithUnused',
      path: 'attendance-by-movie-with-unused',
      component: AttendanceByMovieWithUnused,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по посещаемости по релизам с учетом использованных билетов',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.attendance-by-movie-with-unused.view'],
      },
    },
    {
      name: 'reportAttendanceByHalls',
      path: 'attendance-by-halls',
      component: AttendanceByHalls,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по посещаемости по залам',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.attendance_by_halls.view'],
      },
    },
    {
      name: 'reportAveraging',
      path: 'averaging',
      component: Averaging,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по наработке',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.averaging.view'],
      },
    },
    {
      name: 'reportOccupancy',
      path: 'occupancy',
      component: Occupancy,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по заполняемости',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.occupancy.view'],
      },
    },
    {
      name: 'reportReleaseRentalPeriodFinish',
      path: 'release-rental-period-finish',
      component: ReleaseRentalPeriodFinish,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по концу проката',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.release_rental_period_finish.view'],
      },
    },
    {
      name: 'reportFavoriteMovies',
      path: 'favorite-movies',
      component: FavoriteMovies,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по избранным фильмам',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.favorite_movies.view'],
      },
    },
    {
      name: 'reportDiscountsByPeriod',
      path: 'discounts-by-period',
      component: DiscountsByPeriod,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по предоставленным скидкам',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.discounts_by_period.view'],
      },
    },
    {
      name: 'reportProceedsByMovies',
      path: 'proceeds-by-movies',
      component: ProceedsByMovies,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по выручке от продажи билетов и количеству сеансов',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.proceeds_by_movies.view'],
      },
    },
    {
      name: 'reportPromocodeGroupsByPeriod',
      path: 'promocode-groups-by-period',
      component: PromocodeGroupsByPeriod,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по группам промокодов',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.promocode_groups_by_period.view'],
      },
    },
    {
      name: 'reportSalesByGroup',
      path: 'sales-by-group',
      component: SalesByGroup,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Сводный отчет по продаже товаров с разбивкой по группам',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.sales_by_group.view'],
      },
    },
    {
      name: 'reportConsolidatedSales',
      path: 'consolidated-sales',
      component: ConsolidatedSales,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Сводный отчет по продажам за период (старый)',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.consolidated_sales.view'],
      },
    },
    {
      name: 'reportSalesByApps',
      path: 'sales-by-apps',
      component: SalesByApps,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по каналам онлайн-продаж',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.sales_by_apps.view'],
      },
    },
    {
      name: 'reportProceedsByCinemas',
      path: 'proceeds-by-cinemas',
      component: ProceedsByCinemas,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по сборам сети кинотеатров за период',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.proceeds_by_cinemas.view'],
      },
    },
    {
      name: 'reportProceedsByCinemasByOnline',
      path: 'proceeds-by-cinemas-by-online',
      component: ProceedsByCinemasByOnline,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по сборам сети кинотеатров по каналам онлайн продаж за период',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.proceeds_by_cinemas_by_online.view'],
      },
    },
    {
      name: 'reportProceedsByCinemasByOnlineWithDetails',
      path: 'proceeds-by-cinemas-by-online-with-details',
      component: ProceedsByCinemasByOnlineWithDetails,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по сборам сети кинотеатров по каналам онлайн продаж за период c детализацией',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.proceeds_by_cinemas_by_online_with_details.view'],
      },
    },
    {
      name: 'reportProceedsByCinemasForDistribution',
      path: 'proceeds-by-cinemas-for-distribution',
      component: ProceedsByCinemasForDistribution,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по сборам сети кинотеатров за период (для росписи)',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.proceeds_by_cinemas_for_distribution.view'],
      },
    },
    {
      name: 'reportRefunds',
      path: 'refunds',
      component: Refunds,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по контролю возвратов кассиров',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.refunds.view'],
      },
    },
    {
      name: 'reportAgent',
      path: 'agent',
      component: Agent,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет агента',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.agent.view'],
      },
    },
    {
      name: 'reportAgentServiceFees',
      path: 'agent-service-fees',
      component: AgentServiceFees,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет агента с сервисными сборами',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.agent_service_fees.view'],
      },
    },
    {
      name: 'iikoSales',
      path: 'iiko-sales',
      component: IikoSales,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по продажам Iiko',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.iiko_sales.view'],
      },
    },
    {
      name: 'showSales',
      path: 'show-sales',
      component: ShowSales,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по продажам на сеанс',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.show_sales.view'],
      },
    },
    {
      name: 'priceRatesUsage',
      path: 'price-rates-usage',
      component: PriceRatesUsage,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по использованию ДЦО',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.price_rates_usage.view'],
      },
    },
    {
      name: 'reportKiosksDetailed',
      path: 'kiosks_detailed',
      component: KiosksDetailed,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по продажам киосков',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.kiosks_detailed.view'],
      },
    },
    {
      name: 'reportConsolidatedSalesAccounting',
      path: 'consolidated-sales-accounting',
      component: ConsolidatedSalesAccounting,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Сводный бухгалтерский отчет по продажам за период',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.consolidated_sales_accounting.view'],
      },
    },
    {
      name: 'reportConsolidatedSalesManagerial',
      path: 'consolidated-sales-managerial',
      component: ConsolidatedSalesManagerial,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Сводный управленческий отчет по продажам за период',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.consolidated_sales_managerial.view'],
      },
    },
    {
      name: 'reportConsolidatedSalesOwner',
      path: 'consolidated-sales-owner',
      component: ConsolidatedSalesOwner,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Сводный отчет по продажам за период для собственника',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.consolidated_sales_owner.view'],
      },
    },
    {
      name: 'reportConsolidatedSalesOnMovie',
      path: 'consolidated-sales-on-movie',
      component: ConsolidatedSalesOnMovie,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Сводный отчет по фильмам',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.consolidated_sales_on_movie.view'],
      },
    },
    {
      name: 'reportNetworkSalesByOnlineGroups',
      path: 'network-sales-by-online-groups',
      component: NetworkSalesByOnlineGroups,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по сборам сети кинотеатров по каналам онлайн-продаж (с сервисным сбором)',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.network_sales_by_online_groups.view'],
      },
    },
    {
      name: 'reportSalesByHalls',
      path: 'sales-by-halls',
      component: SalesByHalls,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по каналам онлайн-продаж по залам',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.sales_by_halls.view'],
      },
    },
    {
      name: 'reportProceedsByCinemasByPc',
      path: 'proceeds-by-cinemas-by-pc',
      component: ProceedsByCinemasByPc,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по продажам сети по Пушкинской карте',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.proceeds_by_cinemas_by_pc.view'],
      },
    },
    {
      name: 'reportPromocodes',
      path: 'promocodes',
      component: Promocodes,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Отчет по использованию промокодов',
        breadcrumbs: [
          { name: 'Отчетность' },
        ],
        permissions: ['reports.promocodes.view'],
      },
    },
  ],
};
