<template>
    <multi-view class="report">
      <template #side>
        <params></params>
      </template>

      <template #panel>
        <b-button
            size="sm"
            variant="outline-info"
            :to="{ name: 'reportIndex' }"
        >
            <span slot="text">
              <icon icon="fa-chevron-left"></icon> Назад
            </span>
        </b-button>
      </template>

      <template #content>
        <div
            v-if="report"
            v-html="report"
            id="htmlReport"
            class="p-3 w-100"
        ></div>
      </template>
    </multi-view>
</template>

<script>
import { mapState } from 'vuex';

import MultiView from '../../../layout/MultiView.vue';
import Params from './Params.vue';

export default {
  components: {
    MultiView,
    Params,
  },
  computed: {
    ...mapState('reports/priceRatesUsage', {
      report: (state) => state.report,
    }),
  },
};
</script>
