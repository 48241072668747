export default {
  items: [
    {
      name: 'Аналитика',
      icon: 'mdi-chart-bar',
      auto: true,
      children: [
        {
          name: 'Плановые показатели',
          icon: 'fa-circle-o',
          routeName: 'targets',
        },
        {
          name: 'Маркетинг',
          icon: 'fa-circle-o',
          routeName: 'analyticsMarketing',
        },
        {
          name: 'Прокат',
          icon: 'fa-circle-o',
          routeName: 'analyticsDistribution',
        },
        {
          name: 'Рынок',
          icon: 'fa-circle-o',
          routeName: 'analyticsMarket',
        },
        {
          name: 'Клиенты',
          icon: 'fa-circle-o',
          routeName: 'analyticsCustomers',
        },
        {
          name: 'RFM анализ',
          icon: 'fa-circle-o',
          routeName: 'analyticsRfm',
        },
        {
          name: 'Динамика RFM',
          icon: 'fa-circle-o',
          routeName: 'analyticsRfmDynamic',
        },
      ],
    },
    {
      name: 'Отчетность',
      icon: 'mdi-chart-bar',
      auto: true,
      routeName: 'reportIndex',
    },
    {
      name: 'Маркетинг',
      icon: 'mdi-cart-outline',
      auto: true,
      children: [
        {
          name: 'Акции',
          icon: 'fa-circle-o',
          routeName: 'promotions',
        },
        {
          name: 'Скидки',
          icon: 'fa-circle-o',
          routeName: 'discounts',
        },
        {
          name: 'Ценовые политики',
          icon: 'fa-circle-o',
          children: [
            {
              name: 'Группы ценовых политик',
              routeName: 'priceGroups',
              icon: 'fa-circle-o',
            },
            {
              name: 'Ценовые политики',
              routeName: 'prices',
              icon: 'fa-circle-o',
            },
            {
              name: 'Динамическое ценообразование',
              routeName: 'priceRates',
              icon: 'fa-circle-o',
            },
          ],
        },
        {
          name: 'Сертификаты',
          icon: 'fa-circle-o',
          children: [
            {
              name: 'Группы сертификатов',
              icon: 'fa-circle-o',
              routeName: 'certificateGroups',
            },
            {
              name: 'Сертификаты',
              icon: 'fa-circle-o',
              routeName: 'certificates',
            },
          ],
        },
        {
          name: 'Промокоды',
          icon: 'fa-circle-o',
          children: [
            {
              name: 'Группы промокодов',
              icon: 'fa-circle-o',
              routeName: 'promocodeGroups',
            },
            {
              name: 'Промокоды',
              icon: 'fa-circle-o',
              routeName: 'promocodes',
            },
          ],
        },
        {
          name: 'Планирование рекламы',
          routeName: 'advertisements',
          icon: 'fa-circle-o',
          permissions: ['advertisements.view'],
        },
        {
          name: 'Баннеры для сайта',
          icon: 'fa-circle-o',
          routeName: 'banners',
        },
        {
          name: 'Страницы сайта',
          icon: 'fa-circle-o',
          routeName: 'webPages',
          permissions: ['web_pages.view'],
        },
        {
          name: 'Меню сайта',
          icon: 'fa-circle-o',
          routeName: 'webMenus',
          permissions: ['webMenus.view'],
        },
        {
          name: 'Рекламные акции',
          routeName: 'advPromotions',
          icon: 'fa-circle-o',
          permissions: ['adv_promotions.view'],
        },
        {
          name: 'Уведомления',
          routeName: 'notifications',
          icon: 'fa-comments',
          permissions: ['notifications.view'],
        },
        {
          name: 'Метки',
          routeName: 'tags',
          icon: 'fa-tags',
          permissions: ['tags.view'],
        },
        {
          name: 'Вопрос-ответ',
          icon: 'fa-question-circle-o',
          children: [
            {
              name: 'Вопросы',
              routeName: 'qaItems',
              icon: 'fa-circle-o',
            },
            {
              name: 'Категории вопросов',
              routeName: 'qaCategories',
              icon: 'fa-ticket',
            },
          ],
        },
        {
          name: 'Справочники',
          icon: 'fa-circle-o',
          children: [
            {
              name: 'Льготные категории',
              routeName: 'privileges',
              icon: 'fa-circle-o',
            },
            {
              name: 'Праздничные даты',
              routeName: 'holidays',
              icon: 'fa-circle-o',
            },
            {
              name: 'Ценовые пояса',
              routeName: 'colorSchemes',
              icon: 'fa-circle-o',
            },
          ],
        },
      ],
    },

    {
      name: 'Прокат',
      icon: 'mdi-filmstrip',
      auto: true,
      children: [
        {
          name: 'Дистрибьюторы',
          icon: 'fa-user',
          children: [
            {
              name: 'Прокатчики',
              routeName: 'distributors2',
              icon: 'fa-circle-o',
            },
            {
              name: 'Лаборатории',
              routeName: 'laboratories2',
              icon: 'fa-circle-o',
            },
            {
              name: 'Договоры',
              routeName: 'agreements',
              icon: 'fa-circle-o',
            },
            {
              name: 'Адреса для отчетов',
              routeName: 'distributorReportEmails',
              icon: 'fa-circle-o',
            },
            {
              name: 'ЮЛ для отчетов',
              routeName: 'distributorLegalEntities',
              icon: 'fa-circle-o',
            },
          ],
        },
        {
          name: 'Фильмы',
          routeName: 'movies2',
          icon: 'fa-film',
        },
        {
          name: 'Планирование',
          routeName: 'repertory',
          icon: 'fa-calendar',
        },
        {
          name: 'Расписание сеансов',
          routeName: 'schedule',
          icon: 'fa-clock-o',
        },
        {
          name: 'Открытие продаж',
          routeName: 'sales',
          icon: 'fa-money',
        },
        {
          name: 'Пушкинская карта',
          routeName: 'pushkinsCardEvents2',
          icon: 'fa-calendar',
        },
        {
          name: 'Ключи',
          routeName: 'dcpKeys',
          icon: 'fa-key',
        },
        {
          name: 'Бронирование',
          icon: 'fa-circle-o',
          children: [
            {
              name: 'Типы брони',
              routeName: 'reserveTypes',
              icon: 'fa-circle-o',
            },
          ],
        },
      ],
    },

    {
      name: 'Клиенты',
      icon: 'mdi-account-outline',
      auto: true,
      children: [
        {
          name: 'Физические лица',
          routeName: 'customers',
          icon: 'mdi-account-edit',
          children: [
            {
              name: 'Физические лица',
              routeName: 'customers',
              icon: 'fa-circle-o',
            },
            {
              name: 'Блокировка почтовых доменов',
              routeName: 'emailBlacklistDomains',
              icon: 'fa-ban',
            },
          ],
        },
        {
          name: 'Выборки',
          routeName: 'selections',
          icon: 'fa-user-o',
          children: [
            {
              name: 'Выборка',
              routeName: 'selections',
              icon: 'fa-circle-o',
              permissions: ['selections.view'],
            },
            {
              name: 'Группы выборок',
              routeName: 'selectionGroups',
              icon: 'fa-circle-o',
              permissions: ['selections.view'],
            },
          ],
        },
        {
          name: 'Организации',
          routeName: 'organizations',
          icon: 'mdi-account-multiple-outline',
        },
        {
          name: 'Заказы',
          routeName: 'orders',
          icon: 'fa-circle-o',
        },
        {
          name: 'Транзакции',
          routeName: 'merchantPayments',
          icon: 'fa-circle-o',
        },
      ],
    },
    {
      name: 'Администрирование',
      icon: 'mdi-cogs',
      auto: true,
      children: [
        {
          name: 'Кинотеатры',
          icon: 'fa-circle-o',
          children: [
            {
              name: 'Кинотеатры',
              routeName: 'cinemas',
              icon: 'fa-circle-o',
            },
            {
              name: 'Категории залов',
              routeName: 'hallCategories',
              icon: 'fa-circle-o',
            },
          ],
        },
        {
          name: 'Оборудование',
          routeName: 'equipment',
          icon: 'fa-circle-o',
        },
        {
          name: 'Юридические лица',
          routeName: 'legalEntities',
          icon: 'fa-circle-o',
        },
        {
          name: 'Мерчант аккаунты',
          routeName: 'merchantAccounts',
          icon: 'fa-circle-o',
        },
        {
          name: 'Почтовые аккаунты',
          routeName: 'mailAccounts',
          icon: 'fa-circle-o',
        },
        {
          name: 'Внешние продажи',
          icon: 'fa-circle-o',
          children: [
            {
              name: 'Приложения',
              routeName: 'apps',
              icon: 'fa-circle-o',
            },
            {
              name: 'Группы приложений',
              routeName: 'appGroups',
              icon: 'fa-circle-o',
            },
          ],
        },
        {
          name: 'Товары',
          icon: 'fa-circle-o',
          children: [
            {
              name: 'Товары',
              routeName: 'products',
              icon: 'fa-circle-o',
            },
            {
              name: 'Группы товаров для отображения',
              routeName: 'productGroups',
              icon: 'fa-circle-o',
            },
            {
              name: 'Группы товаров для отчетов',
              routeName: 'productReportGroups',
              icon: 'fa-circle-o',
            },
          ],
        },
        {
          name: 'Пользователи',
          icon: 'fa-user',
          children: [
            {
              name: 'Пользователи',
              routeName: 'users',
              icon: 'fa-user',
            },
            {
              name: 'Роли',
              routeName: 'roles',
              icon: 'fa-tag',
            },
          ],
        },
        {
          name: 'Медиапанели',
          icon: 'mdi-television',
          children: [
            {
              name: 'Медиапанели',
              routeName: 'mediaPanels',
              icon: 'mdi-television',
            },
            {
              name: 'Логи',
              routeName: 'mediaPanelsLogs',
              icon: 'fa-circle-o',
            },
            {
              name: 'Библиотека',
              routeName: 'library',
              icon: 'fa-circle-o',
            },
          ],
        },
      ],
    },
    {
      name: 'Рассылки',
      icon: 'mdi-email',
      auto: true,
      children: [
        {
          name: 'Пользователи',
          icon: 'fa-circle-o',
          children: [
            {
              name: 'Пользователи',
              icon: 'fa-user-o',
              routeName: 'notificationUsers',
            },
            {
              name: 'Тэги',
              icon: 'fa-tag',
              routeName: 'notificationTags',
            },
          ],
        },
        {
          name: 'Кампании',
          icon: 'fa-circle-o',
          routeName: 'notificationCampaigns',
        },
        {
          name: 'Шаблоны',
          icon: 'fa-circle-o',
          routeName: 'notificationTemplates',
        },
      ],
    },
  ],
};
