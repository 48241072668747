export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: false,
  },
  {
    key: 'appId',
    type: 'text',
    label: 'Приложение',
    filterable: false,
    sortable: false,
  },
  {
    key: 'deviceId',
    type: 'text',
    label: 'Девайс',
    filterable: false,
    sortable: false,
  },
  {
    key: 'updatedAt',
    type: 'text',
    label: 'Дата обновления',
    filterable: false,
    sortable: false,
    thStyle: {
      width: '180px',
    },
  },
];
