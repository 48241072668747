<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
            label="Название:"
            :invalid-feedback="errors.name"
            :state="states.name"
        >
          <b-form-input
              type="text"
              autofocus
              size="sm"
              :value="item.name"
              :state="states.name"
              :disabled="loading"
              @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>
        <b-form-group
            class="mt-2"
            label="Тип:"
            :disabled="loading"
            :invalid-feedback="errors.type"
        >
          <b-form-radio-group
              name="day-type"
              :state="states.type"
              :checked="item.type || 'firebase'"
              :disabled="loading"
              @change="updateValue('type', $event)"
              @input="updateValue('type', $event)"
          >
            <b-form-radio value="firebase">Firebase</b-form-radio>
            <b-form-radio value="email">Email</b-form-radio>
          </b-form-radio-group>
        </b-form-group>

        <b-form-group
            label="Тема:"
            :invalid-feedback="errors.subject"
            :state="states.subject"
            v-show="!isEmail"
        >
          <b-form-input
              type="text"
              size="sm"
              :value="item.subject"
              :state="states.subject"
              :disabled="loading"
              @change="updateValue('subject', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Тело:"
            :invalid-feedback="errors.body"
            :state="states.subject"
            v-show="!isEmail"
        >
          <b-form-textarea
              type="text"
              size="sm"
              :value="item.body"
              :state="states.body"
              :disabled="loading"
              @change="updateValue('body', $event)"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
            label="От Email:"
            :invalid-feedback="errors.fromEmail"
            :state="states.fromEmail"
            v-show="isEmail"
        >
          <b-form-input
              type="text"
              autofocus
              size="sm"
              :value="item.fromEmail"
              :state="states.fromEmail"
              :disabled="loading"
              @change="updateValue('fromEmail', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="От Имя:"
            :invalid-feedback="errors.fromName"
            :state="states.fromName"
            v-show="isEmail"
        >
          <b-form-input
              type="text"
              autofocus
              size="sm"
              :value="item.fromName"
              :state="states.fromName"
              :disabled="loading"
              @change="updateValue('fromName', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Шаблон:"
            :invalid-feedback="errors.templateId"
            :state="states.templateId"
            v-show="isEmail"
        >
          <dictionary-provider name="notificationTemplates">
            <template #default="{ items, loading: dictionaryLoading }">
              <b-form-select
                  size="sm"
                  :value="item.templateId"
                  :options="$array.convertToOptions(items, {
                value: null,
                text: '',
              })"
                  :disabled="loading || dictionaryLoading"
                  @change="updateValue('templateId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
            label="Тэги:"
            :invalid-feedback="errors.tags"
            :state="states.tags"
            v-show="isEmail"
        >
          <dictionary-provider name="notificationTags">
            <template #default="{ items, loading: dictionaryLoading }">
              <toggle-list
                  placeholder="Все"
                  :options="convertArrays($array.union(items, item.tags))"
                  :value="item.tags"
                  :disabled="loading || dictionaryLoading"
                  @change="updateValue('tags', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-form-group
            label="Выборки:"
            :invalid-feedback="errors.selections"
            :state="states.selections"
        >
          <dictionary-provider name="selections">
            <template #default="{ items, loading: dictionaryLoading }">
              <toggle-list
                  placeholder="Все"
                  :options="convertArrays($array.union(items, item.selections))"
                  :value="item.selections"
                  :disabled="loading || dictionaryLoading"
                  @change="updateValue('selections', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

        <b-row>
          <b-col>
            <b-form-group
                label="Расписание:"
                :invalid-feedback="errors.schedule"
                :state="states.schedule"
            >
              <b-form-input
                  type="text"
                  size="sm"
                  :value="item.schedule"
                  :state="states.schedule"
                  :disabled="loading"
                  @change="updateValue('schedule', $event)"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
                label="Включен запуск по расписанию"
                :invalid-feedback="errors.isScheduled"
                :state="states.isScheduled"
            >
              <b-form-checkbox
                  size="sm"
                  :checked="!!item.isScheduled"
                  :state="states.isScheduled"
                  :disabled="loading"
                  @change="updateValue('isScheduled', $event)"
              ></b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group
            label="Старт:"
            :invalid-feedback="errors.startDate"
            :state="states.startDate"
        >
          <date-time
              size="sm"
              :formatOutput="'YYYY-MM-DD HH:mm'"
              :value="item.startDate"
              :state="states.startDate"
              :disabled="loading"
              @selected="updateValue('startDate', $event)"
          ></date-time>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import ToggleList from '@/components/fields/ToggleList.vue';
import DictionaryProvider from '@/providers/DictionaryProvider';
import DateTime from '@/components/fields/DateTime.vue';

export default {
  components: {
    DictionaryProvider,
    ToggleList,
    DateTime
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      isEmail: this.item.type === 'email',
    };
  },
  methods: {
    updateValue(field, value) {
      if (field === 'type') {
        this.$data.isEmail = value === 'email';
      }

      this.$emit('change', {
        field,
        value,
      });
    },
    convertArrays(tags) {
      if (!tags) {
        return [];
      }

      return tags.map((tag) => ({
        id: tag.id,
        name: tag.name,
      }));
    },
  },
};
</script>
