<template>
  <div class="table-container discounts">
    <collection-provider
      collection="discounts"
      ref="collection"
      :filter="{ mode: 'all' }"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :permissions="$user.permissions"
          :panel-actions="datatable.panelActions"
          :item-actions="datatable.itemActions"
          :checked="datatable.checked"
          @change-checked="datatable.checked = $event"
          @panel-action="performPanelAction($event)"
          @item-action="performItemAction($event)"
        >
          <template #filter(settings)>
            <dictionary-provider name="cinemas">
              <template #default="{ items }">
                <b-form-select
                  size="sm"
                  :value="data.filter.cinemaId"
                  :options="convertCinemas(items)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('cinemaId', $event)"
                >
                  <template #first>
                    <b-form-select-option :value="null"></b-form-select-option>
                  </template>
                </b-form-select>
              </template>
            </dictionary-provider>
          </template>
          <template #filter(enabled)>
            <b-form-select
              size="sm"
              :value="data.filter.mode"
              :options="modes"
              :disabled="data.loading"
              @change="actions.changeFilter('mode', $event)"
            ></b-form-select>
          </template>
          <template #cell(name)="data">
            <div>{{data.item.name}}</div>
            <div
              v-if="data.item.description"
              class="text-muted mt-2 description"
              :title="data.item.description"
            >{{data.item.description}}</div>
            <discount-formula
              class="mt-2"
              :item="data.item"
            ></discount-formula>
            <discount-min-price
              class="mt-2"
              :item="data.item"
            ></discount-min-price>
          </template>
          <template #cell(date)="data">
            <discount-period :item="data.item"></discount-period>
          </template>
          <template #cell(enabled)="data">
            {{getDiscountStatusName(data.item.isEnabled)}}
          </template>
          <template #cell(settings)="data">
            <discount-settings :item="data.item"></discount-settings>
          </template>
        </datatable>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.discount.show"
      create-title="Создание скидки"
      edit-title="Редактирование скидки"
      collection="discounts"
      :id="modals.discount.id"
      @close="modals.discount.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <discount-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></discount-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import DiscountMinPrice from '@/pages/discounts/DiscountMinPrice.vue';

import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import panelActions from './_index.panelActions';
import itemActions from './_index.itemActions';

import Datatable from '../../components/datatable/Datatable.vue';

import CollectionProvider from '../../providers/CollectionProvider';
import DictionaryProvider from '../../providers/DictionaryProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import DiscountForm from './DiscountForm.vue';
import DiscountPeriod from './DiscountPeriod.vue';
import DiscountFormula from './DiscountFormula.vue';
import DiscountSettings from './DiscountSettings.vue';

import datetime from '../../utils/datetime';

export default {
  components: {
    DiscountMinPrice,
    CollectionProvider,
    DictionaryProvider,
    ModalModelForm,
    Datatable,
    DiscountForm,
    DiscountFormula,
    DiscountSettings,
    DiscountPeriod,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        panelActions,
        itemActions,
      },
      modals: {
        discount: {
          show: false,
          id: 0,
        },
      },
      discountStatuses: [
        {
          value: false,
          text: 'Остановлена',
        },
        {
          value: true,
          text: 'Активна',
        },
      ],
      modes: [
        {
          value: 'all',
          text: 'Все',
        },
        {
          value: 'enabled',
          text: 'Активные',
        },
        {
          value: 'disabled',
          text: 'Остановленные',
        },
        {
          value: 'past',
          text: 'Прошедшие',
        },
      ],
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    getDiscountStatusName(value) {
      return this.discountStatuses.find((status) => status.value === value)?.text;
    },

    openCreateDiscountForm() {
      this.modals.discount.id = '';
      this.modals.discount.show = true;
    },

    async removeCheckedDiscounts() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    pauseCheckedDiscounts() {
      this.$refs.collection.changeItems(this.datatable.checked, { isEnabled: 0 });
      this.datatable.checked = [];
    },

    resumeCheckedDiscounts() {
      this.$refs.collection.changeItems(this.datatable.checked, { isEnabled: 1 });
      this.datatable.checked = [];
    },

    openEditDiscountForm(id) {
      this.modals.discount.id = id;
      this.modals.discount.show = true;
    },

    async removeDiscount(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    pauseDiscount(id) {
      this.$refs.collection.changeItem(id, { isEnabled: 0 });
    },

    resumeDiscount(id) {
      this.$refs.collection.changeItem(id, { isEnabled: 1 });
    },

    formatDateTime(date) {
      return datetime.formatDateTime(date);
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        value: cinema.id,
        text: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },
  },
};
</script>

<style scoped>
.description {
  max-width: 20vw;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
