<template>
  <div class="table-container">
    <collection-provider
      collection="employees"
      ref="collection"
      :filter="{ cinemaId }"
      :disable-fetching="!cinemaId"
    >
      <template #default="{ data, actions }">
        <dictionaries-provider :names="['employeeTypes']">
          <template #default="{ employeeTypes }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :permissions="$user.permissions"
              :item-actions="datatable.itemActions"
              @item-action="performItemAction($event)"
            >
              <template #filter(positionId)>
                <b-form-select
                  size="sm"
                  :value="data.filter.positionId"
                  :options="$array.convertToOptions(employeeTypes)"
                  :disabled="data.loading"
                  @change="actions.changeFilter('positionId', $event)"
                ></b-form-select>
              </template>
              <template #filter(isActive)>
                <b-form-select
                    size="sm"
                    :value="data.filter.isActive"
                    :options="isActiveStatuses"
                    :disabled="data.loading"
                    @change="actions.changeFilter('isActive', $event)"
                ></b-form-select>
              </template>
              <template #cell(positionId)="data">
                {{$array.getItemText(employeeTypes , data.item.positionId)}}
              </template>
              <template #cell(isActive)="data">
                {{data.item.isActive ? 'Активен' : 'Не активен'}}
              </template>
            </datatable>
          </template>
        </dictionaries-provider>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.employee.show"
      create-title="Создание сотрудника"
      edit-title="Редактирование данных сотрудника"
      collection="employees"
      :id="modals.employee.id"
      :init="{ cinemaId }"
      @close="modals.employee.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <employee-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></employee-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';
import CollectionProvider from '../../providers/CollectionProvider';
import DictionariesProvider from '../../providers/DictionariesProvider';
import Datatable from '../../components/datatable/Datatable.vue';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import EmployeeForm from './EmployeeForm.vue';

import fields from './_employees.fields';
import itemActions from './_employees.itemActions';

export default {
  components: {
    CollectionProvider,
    DictionariesProvider,
    Datatable,
    EmployeeForm,
    ModalModelForm,
  },
  props: {
    cinemaId: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        fields,
        itemActions,
      },
      modals: {
        employee: {
          show: false,
          id: '',
        },
      },
      isActiveStatuses: [
        {
          value: null,
          text: '',
        },
        {
          value: false,
          text: 'Не активен',
        },
        {
          value: true,
          text: 'Активен',
        },
      ],
    };
  },
  computed: {
    isActive() {
      return this.isActive ? 'Да' : 'Нет';
    },
  },
  methods: {
    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateEmployeeForm() {
      this.modals.employee.id = null;
      this.modals.employee.show = true;
    },

    openEditEmployeeForm(id) {
      this.modals.employee.id = id;
      this.modals.employee.show = true;
    },

    async removeEmployee(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>

<style scoped>
.employees {
  padding: 0;
  overflow-y: scroll;
  overflow-y: auto;
}
</style>
