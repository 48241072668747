<template>
  <div class="report p-2">
    <b-form-group
      label="Период:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.dateStart']} ${errors['filter.dateEnd']}`"
      :state="states['filter.dateStart'] && states['filter.dateEnd']"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="filter.dateStart"
        :state="states['filter.dateStart']"
        :disabled="loading"
        @change="setFilter({ field: 'dateStart', value: $event }, onChange($event, 'report-discounts-by-period/dateStart'))"
      ></date-popover>
      <date-popover
        size="sm"
        :value="filter.dateEnd"
        :state="states['filter.dateEnd']"
        :disabled="loading"
        @change="setFilter({ field: 'dateEnd', value: $event }, onChange($event, 'report-discounts-by-period/dateEnd'))"
      ></date-popover>
    </b-form-group>

    <b-form-group
        class="mt-2"
        label="Кинотеатры:"
        :disabled="loading"
        :invalid-feedback="errors['filter.cinemaIds']"
        :state="states['filter.cinemaIds']"
    >
      <dictionary-provider name="cinemas">
        <template #default="{ items: cinemas, loading: cinemasLoading }">
          <toggle-list
              size="sm"
              placeholder="Все"
              :options="convertCinemas(cinemas)"
              :value="convertToDictionaryFromIds(filter.cinemaIds, cinemas)"
              :disabled="loading || cinemasLoading"
              :state="states['filter.cinemaIds']"
              @change="setFilter({ field: 'cinemaIds', value: convertFromDictionaryToIds($event) })"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Выборка:"
      :disabled="loading"
      :invalid-feedback="errors['params.compareDatesBy']"
    >
      <b-form-radio-group
        name="compare-dates-by"
        :state="states['params.compareDatesBy']"
        :checked="params.compareDatesBy"
        :disabled="loading"
        @change="setParam({ field: 'compareDatesBy', value: $event })"
      >
        <b-form-radio value="created_at">По дате продажи</b-form-radio>
        <b-form-radio value="show_date">По дате сеанса</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <div class="mt-4">
      <b-button
        variant="info"
        size="sm"
        :disabled="loading"
        @click="fetchReport({ format: 'html' })"
      >Просмотр</b-button>
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
        @click="printReport()"
      ><icon icon="fa-print"></icon> Печать</b-button>
      <b-dropdown
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
      >
        <template #button-content>
          <icon icon="fa-save"></icon>
        </template>
        <b-dropdown-item
          v-for="format in formats"
          :key="format.value"
          @click="fetchReport({ format: format.value })"
        >{{format.text}}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DictionaryProvider from '../../../providers/DictionaryProvider';
import DatePopover from '../../../components/fields/DatePopover.vue';
import ToggleList from '../../../components/fields/ToggleList.vue';
import datetime from '../../../utils/datetime';

export default {
  components: {
    DatePopover,
    DictionaryProvider,
    ToggleList,
  },
  data() {
    return {
      format: 'xlsx',
      formats: [
        { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'XLSX' },
      ],
    };
  },
  computed: {
    ...mapState('reports/discountsByPeriod', {
      params: (state) => state.params,
      filter: (state) => state.filter,
      loading: (state) => state.loading,
      errors: (state) => state.errors,
      states: (state) => state.states,
    }),
  },
  created() {
    let dateStart = window.localStorage.getItem('report-discounts-by-period/dateStart');
    if (!dateStart) {
      dateStart = datetime.convertDateToDbFormat(new Date());
    }
    let dateEnd = window.localStorage.getItem('report-discounts-by-period/dateEnd');
    if (!dateEnd) {
      dateEnd = datetime.convertDateToDbFormat(new Date());
    }

    this.setParam({ field: 'compareDatesBy', value: 'show_date' });
    this.setFilter({ field: 'dateStart', value: dateStart });
    this.setFilter({ field: 'dateEnd', value: dateEnd });
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      setParam: 'reports/discountsByPeriod/setParam',
      setFilter: 'reports/discountsByPeriod/setFilter',
      fetchReport: 'reports/discountsByPeriod/fetchReport',
      reset: 'reports/discountsByPeriod/reset',
    }),

    onChange(value, name) {
      window.localStorage.setItem(name, value);
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    convertToDictionaryFromIds(ids, items) {
      if (!ids || !items) {
        return [];
      }

      return ids.map((id) => items.find((item) => item.id === id));
    },

    convertFromDictionaryToIds(items) {
      if (!items) {
        return [];
      }

      return items.map((item) => item.id);
    },

    async printReport() {
      const [err, data] = await this.fetchReport({ format: 'html' });

      if (err || !data) {
        return;
      }

      this.$nextTick(() => {
        this.$htmlToPaper('htmlReport');
      });
    },
  },
};
</script>

<style scoped>
 .report >>> .multiselect__tag,
 .report >>> .multiselect__option {
   white-space: normal;
 }
</style>
