export default [
  {
    key: 'id',
    type: 'text',
    label: '#',
    filterable: false,
    sortable: true,
  },
  {
    key: 'name',
    type: 'text',
    label: 'Название',
    filterable: true,
    sortable: true,
  },
  {
    key: 'title',
    type: 'text',
    label: 'Тема (отправляется пользователю)',
    filterable: true,
    sortable: true,
  },
  {
    key: 'createdAt',
    type: 'datetime',
    label: 'Создан',
    filterable: false,
    sortable: true,
  },
  {
    key: 'updatedAt',
    type: 'datetime',
    label: 'Обновлен',
    filterable: false,
    sortable: true,
  },
];
