<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Имя:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            autofocus
            size="sm"
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Телефон:"
            :invalid-feedback="errors.phone"
            :state="states.phone"
        >
          <b-form-input
              type="text"
              size="sm"
              :value="item.phone"
              :state="states.phone"
              :disabled="loading"
              @change="updateValue('phone', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Email:"
            :invalid-feedback="errors.email"
            :state="states.email"
        >
          <b-form-input
              type="text"
              size="sm"
              :value="item.email"
              :state="states.email"
              :disabled="loading"
              @change="updateValue('email', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
            label="Тэги:"
            :invalid-feedback="errors.tags"
            :state="states.tags"
        >
          <dictionary-provider name="notificationTags">
            <template #default="{ items, loading: dictionaryLoading }">
              <toggle-list
                  placeholder="Все"
                  :options="convertTags($array.union(items, item.tags))"
                  :value="item.tags"
                  :disabled="loading || dictionaryLoading"
                  @change="updateValue('tags', $event)"
              ></toggle-list>
            </template>
          </dictionary-provider>
        </b-form-group>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import DictionaryProvider from '@/providers/DictionaryProvider';
import ToggleList from '@/components/fields/ToggleList.vue';

export default {
  components: {
    ToggleList,
    DictionaryProvider,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    convertTags(tags) {
      if (!tags) {
        return [];
      }

      return tags.map((tag) => ({
        id: tag.id,
        name: tag.name,
      }));
    },
  },
};
</script>
