<template>
  <div class="pt-3">
    <b-table
      caption-top
      class="mb-0"
      :items="convertItems"
      :fields="fields"
    >
      <template #cell(refundedAt)="data">
        <div v-html="data.item.refundedAt"></div>
      </template>
      <template #cell(price)="data">
        <div v-html="data.item.price"></div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      default: [],
    },
  },
  data() {
    return {
      fields: [
        { key: 'number', label: 'Номер' },
        { key: 'price', label: 'Итоговая цена' },
        { key: 'refundedAt', label: 'Возвращен' },
      ],
    };
  },
  computed: {
    convertItems() {
      return this.items
        .map((item) => ({
          number: `${item.certificate.number}`,
          price: `${this.formatPrice(item.price)}`,
          refundedAt: item.refundedAt ? `<span class="text-danger">${this.$datetime.format(item.refundedAt, this.$datetime.datetimeFormat)}</span>` : '—',
        }));
    },
  },
  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat("ru-RU").format(price);
    },
  }
};
</script>

<style scoped>
.table >>> th {
  font-weight: 400;
  font-size: 14px;
}
.table >>> td {
  font-weight: 300;
  font-size: 14px;
}
</style>
