var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container notificationUsers"},[_c('collection-provider',{ref:"collection",attrs:{"collection":"notificationUsers"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('dictionary-provider',{attrs:{"name":"notificationTags"}},[[_c('datatable',_vm._b({attrs:{"fields":_vm.datatable.fields,"panel-actions":_vm.datatable.panelActions,"item-actions":_vm.datatable.itemActions,"checked":_vm.datatable.checked,"permissions":_vm.$user.permissions},on:{"change-checked":function($event){_vm.datatable.checked = $event},"panel-action":function($event){return _vm.performPanelAction($event)},"item-action":function($event){return _vm.performItemAction($event)}},scopedSlots:_vm._u([{key:"filter(tagId)",fn:function(){return [_c('dictionary-provider',{attrs:{"name":"notificationTags"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var items = ref.items;
return [_c('b-form-select',{attrs:{"size":"sm","value":data.filter.tagId,"options":_vm.$array.convertToOptions(items),"disabled":data.loading},on:{"change":function($event){return actions.changeFilter('tagId', $event)}}})]}}],null,true)})]},proxy:true},{key:"cell(tagId)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$array.convertToText(data.item.tags))+" ")]}}],null,true)},'datatable',Object.assign({}, data, actions),false))]],2)]}}])}),(_vm.modals.entity.show)?_c('modal-model-form',{attrs:{"create-title":"Создание пользователя","edit-title":"Редактирование пользователя","collection":"notificationUsers","id":_vm.modals.entity.id},on:{"close":function($event){_vm.modals.entity.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var actions = ref.actions;
return [_c('entity-form',{attrs:{"loading":data.loading,"errors":data.errors,"states":data.states,"item":data.item},on:{"change":function($event){return actions.setValue($event.field, $event.value)}}})]}}],null,false,3263598086)}):_vm._e(),(_vm.modals.bulkAdd.show)?_c('b-modal',{attrs:{"visible":true,"hide-footer":""},on:{"hidden":function($event){_vm.modals.bulkAdd.show = false},"saved":function($event){return _vm.$refs.collection.fetchItems()}}},[_c('form',{staticClass:"filter d-flex",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"CSV файл:","invalid-feedback":_vm.errors.files,"state":_vm.states.files}},[_c('label',[_c('input',{attrs:{"type":"file"},on:{"change":_vm.onChangeFileUpload}})])])],1)],1),_c('b-row',[_c('b-col',{attrs:{"align":"left"}},[_c('a',{attrs:{"href":"#"},on:{"click":_vm.downloadExample}},[_vm._v("Пример")])])],1),_c('b-row',[_c('b-col',{attrs:{"align":"right"}},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"info","size":"sm","type":"submit"}},[_vm._v("Применить ")])],1)],1)],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }