<template>
  <modal-form-dialog
    title="Список устройств"
    size="xl"
    :close="() => true"
    :hideFooter="true"
    @hidden="$emit('hide')"
  >
    <collection-provider
      collection="customerDevices"
      ref="collection"
      :filter="{ customer_id: id }"
    >
      <template #default="{ data, actions }">
        <datatable
          v-bind="{ ...data, ...actions }"
          :fields="datatable.fields"
          :checked="datatable.checked"
          :permissions="$user.permissions"
          @change-checked="datatable.checked = $event"
        >
          <template #cell(appId)="data">
            {{ data.item.app.name }}
          </template>
          <template #cell(deviceId)="data">
            {{ data.item.deviceId }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt }}
          </template>
        </datatable>
      </template>
    </collection-provider>
  </modal-form-dialog>
</template>

<script>
import { mapState } from 'vuex';

import CollectionProvider from '../../providers/CollectionProvider';

import ModalFormDialog from '../../components/modal/ModalFormDialog.vue';
import Datatable from '../../components/datatable/Datatable.vue';

import fields from './_customerDevices.fields';

export default {
  components: {
    CollectionProvider,
    ModalFormDialog,
    Datatable,
  },
  props: {
    id: {
      default: null,
    },
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
      },
    };
  },
  computed: {
    ...mapState('dictionaries/apps', {
      apps: (state) => state.items,
    }),
  },
  async created() {
    await this.$store.dispatch('dictionaries/apps/fetchItems');
  },
};
</script>

<style lang="scss" scoped>
.token {
  word-break: break-all;
}
</style>
