<template>
  <div class="d-flex">
    <b-button
      v-if="$user.can('cinema_documents.edit')"
      size="sm"
      variant="outline-info"
      class="mr-1"
      :disabled="disabled"
      @click="$emit('create-cinema-document')"
    >
      <icon icon="fa-plus"></icon> Добавить документ
    </b-button>
    <b-button
      v-if="$user.can('cinemas.edit')"
      size="sm"
      variant="info"
      :disabled="disabled"
      @click="$emit('create-cinema')"
    >
      <icon icon="fa-plus"></icon> Добавить кинотеатр
    </b-button>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      default: false,
    },
  },
};
</script>
