<template>
  <form
    class="p-2"
    @submit.prevent="handleSubmit"
  >
    <b-form-group label="Номер заказа">
      <b-form-input
        type="number"
        size="sm"
        :disabled="loading"
        :value="filter.id"
        @change="setFilter({ field: 'id', value: $event })"
      ></b-form-input>
    </b-form-group>

    <b-form-group label="Номер телефона">
      <b-form-input
        type="text"
        class="form-control form-control-sm"
        :disabled="loading"
        :value="filter.customerPhone"
        @change="setFilter({field: 'customerPhone', value: $event})"
      ></b-form-input>
    </b-form-group>
<!--
    <b-form-group label="Номер карты">
      <masked-input
        type="text"
        class="form-control form-control-sm"
        :disabled="loading"
        :mask="[/\d/, /\d/, /\d/, /\d/]"
        :placeholder="cardNumberPlaceholder"
        :guide="false"
        :value="filter.cardNumber"
        @change="setFilter({field: 'cardNumber', value: $event.target.value})"
      ></masked-input>
    </b-form-group>
-->
    <b-form-group label="Дата и время создания заказа">
      <date-time-popover
        size="sm"
        class="mb-2"
        :value="filter.createdAtStart"
        :disabled="loading"
        @change="setFilter({ field: 'createdAtStart', value: roundToDayStart($event) })"
      ></date-time-popover>

      <date-time-popover
        size="sm"
        :value="filter.createdAtEnd"
        :disabled="loading"
        @change="setFilter({ field: 'createdAtEnd', value: roundToDayEnd($event) })"
      ></date-time-popover>
    </b-form-group>

    <b-form-group label="Дата и время сеанса">
      <date-time-popover
        size="sm"
        class="mb-2"
        :value="filter.showDatetimeStart"
        :disabled="loading"
        @change="setFilter({ field: 'showDatetimeStart', value: roundToDayStart($event) })"
      ></date-time-popover>

      <date-time-popover
        size="sm"
        class="mb-2"
        :value="filter.showDatetimeEnd"
        :disabled="loading"
        @change="setFilter({ field: 'showDatetimeEnd', value: roundToDayEnd($event) })"
      ></date-time-popover>
    </b-form-group>

    <b-form-group label="Источник продажи">
      <dictionary-provider name="appGroups">
        <template #default="{ items: appGroups, loading: appGroupsLoading }">
          <b-form-select
            size="sm"
            :value="filter.appGroupId"
            :options="$array.convertToOptions(appGroups, null)"
            :disabled="loading || appGroupsLoading"
            @change="setFilter({field: 'appGroupId', value: $event})"
          >
            <template v-slot:first>
              <b-form-select-option :value="null"></b-form-select-option>
            </template>          
          </b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group label="Тип оплаты">
      <dictionary-provider name="paymentTypes">
        <template #default="{ items: paymentTypes, loading: paymentTypesLoading }">
          <b-form-select
            size="sm"
            :value="filter.paymentTypeId"
            :options="$array.convertToOptions(paymentTypes, null)"
            :disabled="loading || paymentTypesLoading"
            @change="setFilter({field: 'paymentTypeId', value: $event})"
          >
            <template v-slot:first>
              <b-form-select-option :value="null"></b-form-select-option>
            </template>          
          </b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group label="Статус заказа">
      <b-form-select
        size="sm"
        :value="filter.status"
        :options="[
          {value: null, text: ''},
          {value: 'approved', text: 'Подтвержден'},
          {value: 'not_approved', text: 'Не подтвержден'},
          {value: 'refunded', text: 'Возвращен'},
        ]"
        :disabled="loading"
        @change="setFilter({field: 'status', value: $event})"
      ></b-form-select>
    </b-form-group>

    <b-form-group
      class="mt-2"
      :disabled="loading"
    >
      <b-form-checkbox
        :checked="!!filter.used_promocode"
        :disabled="loading"
        @change="setFilter({field: 'used_promocode', value: $event ? 1 : 0})"
      >Использован промокод</b-form-checkbox>
    </b-form-group>

    <b-form-group
      class="mt-2"
      :disabled="loading"
    >
      <b-form-checkbox
        :checked="!!filter.used_certificate"
        :disabled="loading"
        @change="setFilter({field: 'used_certificate', value: $event ? 1 : 0})"
      >Использован сертификат</b-form-checkbox>
    </b-form-group>

    <b-form-group
      class="mt-2"
      :disabled="loading"
    >
      <b-form-checkbox
        :checked="!!filter.contains_bar_items"
        :disabled="loading"
        @change="setFilter({field: 'contains_bar_items', value: $event ? 1 : 0})"
      >Содержит товары бара</b-form-checkbox>
    </b-form-group>

    <div class="mt-4">
      <b-button
        variant="info"
        size="sm"
        type="submit"
        class="mr-2"
        :disabled="loading"
      >Применить</b-button>
    </div>
  </form>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import MaskedInput from 'vue-text-mask';
import { placeholder } from '../../utils/placeholder';
import { MaskHelper } from '../../utils/mask';
import DictionaryProvider from '../../providers/DictionaryProvider';
import FilterDropdown from '../../components/filters/Dropdown.vue';
import DateTimePopover from '../../components/fields/DateTimePopover.vue';

export default {
  components: {
    MaskedInput,
    DateTimePopover,
    DictionaryProvider,
    FilterDropdown,
  },
  props: {
    cinemaId: {
      default: null,
    },
    cardNumberPlaceholder: {
      default: '0000',
    },    
  },
  data() {
    return {
      placeholder,
      mask: MaskHelper,
    };
  },
  computed: {
    ...mapState('data/orders', {
      filter: (state) => state.filter,
      loading: (state) => state.loading,
    }),
  },
  destroyed() {
    this.resetFilter();
  },
  methods: {
    ...mapActions({
      setFilter: 'data/orders/setFilter',
      fetchItems: 'data/orders/fetchItems',
      resetFilter: 'data/orders/resetFilter',
    }),

    async handleSubmit() {
      await this.fetchItems();
    },

    roundToDayStart(value) {
      const now = new Date();
      const selected = new Date(value);

      if (now.getHours() !== selected.getHours() || now.getMinutes() !== selected.getMinutes()) {
        return value;
      }

      selected.setHours(0, 0, 0, 0);

      return moment(selected).format('YYYY-MM-DD HH:mm');
    },

    roundToDayEnd(value) {
      const now = new Date();
      const selected = new Date(value);

      if (now.getHours() !== selected.getHours() || now.getMinutes() !== selected.getMinutes()) {
        return value;
      }

      selected.setHours(23, 59, 0, 0);

      return moment(selected).format('YYYY-MM-DD HH:mm');
    },
  },
};
</script>
