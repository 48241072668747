export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditNotificationsTemplateForm',
        permission: 'notifications.templates.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeTemplate',
        permission: 'notifications.templates.edit',
      },
      {
        type: 'button',
        text: 'Дублировать',
        action: 'duplicateTemplate',
        permission: 'notifications.templates.edit',
      },
    ],
  },
];
