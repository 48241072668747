export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditNotificationsCampaignForm',
        permission: 'notifications.campaigns.edit',
      },
      {
        type: 'button',
        text: 'Тест',
        action: 'openTestForm',
        permission: item.type === 'email' ? 'notifications.campaigns.edit' : 'fake_role',
      },
      {
        type: 'button',
        text: 'Запуск',
        action: 'startCampaign',
        permission: 'notifications.campaigns.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeCampaign',
        permission: 'notifications.campaigns.edit',
      },
      {
        type: 'button',
        text: 'Экспорт пользователей',
        action: 'exportCampaign',
        permission: 'notifications.campaigns.view',
      },
      {
        type: 'button',
        text: 'Отчет',
        action: 'exportCampaignReport',
        permission: 'notifications.campaigns.view',
      },
    ],
  },
];
