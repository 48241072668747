<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Название:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            autofocus
            size="sm"
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="ИНН:"
          :invalid-feedback="errors.inn"
          :state="states.inn"
        >
          <masked-input
            type="text"
            class="form-control form-control-sm"
            :value="item.inn"
            :guide="false"
            :disabled="loading"
            :state="states.inn"
            @change="updateValue('inn', $event.target.value)"
          />
        </b-form-group>

        <b-form-group
          label="КПП:"
          :invalid-feedback="errors.kpp"
          :state="states.kpp"
        >
          <masked-input
            type="text"
            class="form-control form-control-sm"
            :value="item.kpp"
            :guide="true"
            :disabled="loading"
            :state="states.kpp"
            @change="updateValue('kpp', $event.target.value)"
          />
        </b-form-group>

        <b-form-group
          label="ОГРН:"
          :invalid-feedback="errors.ogrn"
          :state="states.ogrn"
        >
          <masked-input
            type="text"
            class="form-control form-control-sm"
            :value="item.ogrn"
            :guide="true"
            :disabled="loading"
            :state="states.ogrn"
            @change="updateValue('ogrn', $event.target.value)"
          />
        </b-form-group>

        <b-form-group
          label="Телефон (начиная с 7):"
          :invalid-feedback="errors.phone"
          :state="states.phone"
        >
          <masked-input
            type="text"
            class="form-control form-control-sm"
            :value="item.phone"
            :placeholder="Array(11).fill('X')"
            :mask="Array(11).fill(/\d/)"
            :guide="true"
            :disabled="loading"
            :state="states.phone"
            @change="updateValue('phone', $event.target.value)"
          />
        </b-form-group>

        <b-form-group
          label="Юридический адрес:"
          :invalid-feedback="errors.legalAddress"
          :state="states.legalAddress"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.legalAddress"
            :state="states.legalAddress"
            :disabled="loading"
            @change="updateValue('legalAddress', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Почтовый адрес:"
          :invalid-feedback="errors.postalAddress"
          :state="states.postalAddress"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.postalAddress"
            :state="states.postalAddress"
            :disabled="loading"
            @change="updateValue('postalAddress', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="ФИО директора:"
          :invalid-feedback="errors.director"
          :state="states.director"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.director"
            :state="states.director"
            :disabled="loading"
            @change="updateValue('director', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="ФИО директора в родительном падеже:"
          :invalid-feedback="errors.directorGenitive"
          :state="states.directorGenitive"
        >
          <b-form-input
            type="text"
            size="sm"
            :value="item.directorGenitive"
            :state="states.directorGenitive"
            :disabled="loading"
            @change="updateValue('directorGenitive', $event)"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Подпись директора:"
          :invalid-feedback="errors.directorSignature"
          :state="states.directorSignature"
        >
          <file-uploader
            :disabled="loading"
            @change="handleFile('directorSignature', $event)"
          ></file-uploader>
        </b-form-group>
      </b-col>
      <b-col v-if="item.directorSignature">
        <image-viewer
            :image="item.directorSignature"
            @remove="updateValue('directorSignature', '')"
        ></image-viewer>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
            label="Печать:"
            :invalid-feedback="errors.directorSeal"
            :state="states.directorSeal"
        >
          <file-uploader
              :disabled="loading"
              @change="handleFile('directorSeal', $event)"
          ></file-uploader>
        </b-form-group>
      </b-col>
      <b-col v-if="item.directorSeal">
        <image-viewer
            :image="item.directorSeal"
            @remove="updateValue('directorSeal', '')"
        ></image-viewer>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Система налогообложения:"
          :invalid-feedback="errors.taxSystemId"
          :state="states.taxSystemId"
        >
          <dictionary-provider name="taxSystems">
            <template #default="{ items }">
              <b-form-select
                size="sm"
                :value="item.taxSystemId"
                :options="$array.convertToOptions(items)"
                :state="states.taxSystemId"
                :disabled="loading"
                @change="updateValue('taxSystemId', $event)"
              ></b-form-select>
            </template>
          </dictionary-provider>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          label="Плательщик НДС:"
          :invalid-feedback="errors.vatType"
          :state="states.vatType"
        >
          <b-form-select
            size="sm"
            :value="item.vatType"
            :options="$array.convertToOptions(vatTypes, null)"
            :state="states.vatType"
            :disabled="loading"
            @change="updateValue('vatType', $event)"
          ></b-form-select>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import DictionaryProvider from '../../providers/DictionaryProvider';
import FileUploader from '../../components/fields/FileUploader.vue';
import ImageViewer from '../../components/ImageViewer.vue';

import vatTypes from './_vatTypes';

export default {
  components: {
    DictionaryProvider,
    MaskedInput,
    FileUploader,
    ImageViewer,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      vatTypes,
    };
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },
    async handleFile(name, files) {
      const image = await this.$fileManager.getBase64(files[0], { maxWidth: 200 });

      this.updateValue(name, image);
    },
  },
};
</script>
