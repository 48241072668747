export default ({ item }) => [
  {
    type: 'dropdown',
    icon: 'fa-list',
    variant: 'default-outline',
    items: [
      {
        type: 'button',
        text: 'Редактировать',
        action: 'openEditNotificationsUserForm',
        permission: 'notifications.users.edit',
      },
      {
        type: 'button',
        text: 'Удалить',
        action: 'removeUser',
        permission: 'notifications.users.edit',
      },
    ],
  },
];
