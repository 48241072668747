<template>
  <div class="p-2">
    <b-form-group
      label="Период для поиска релизов:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.dateStart']} ${errors['filter.dateEnd']}`"
      :state="states['filter.dateStart'] && states['filter.dateEnd']"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="filter.dateStart"
        :state="states['filter.dateStart']"
        :disabled="loading"
        @change="setFilter({ field: 'dateStart', value: $event }),setFilter({ field: 'distributorAgreementId', value: null }), setFilter({ field: 'distributorAgreementFormatId', value: null })"
      ></date-popover>
      <date-popover
        size="sm"
        :value="filter.dateEnd"
        :state="states['filter.dateEnd']"
        :disabled="loading"
        @change="setFilter({ field: 'dateEnd', value: $event }), setFilter({ field: 'distributorAgreementId', value: null }), setFilter({ field: 'distributorAgreementFormatId', value: null })"
      ></date-popover>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Релиз:"
      :disabled="loading || !params.cinemaId"
      :invalid-feedback="errors['filter.distributorAgreementId']"
    >
      <dictionary-provider
        name="distributorAgreements"
        :filter="{ cinemaId: params.cinemaId, dateStart: filter.dateStart, dateEnd: filter.dateEnd }"
        :disable-fetching="!params.cinemaId || !filter.dateStart || !filter.dateEnd"
      >
        <template #default="{ items: agreements, loading: agreementsLoading }">
          <b-form-select
            size="sm"
            :disabled="agreementsLoading || loading || (!params.cinemaId || !filter.dateStart || !filter.dateEnd)"
            :options="convertAgreements(agreements)"
            :value="selectedAgreement"
            :state="states['filter.distributorAgreementId'] && states['filter.distributorAgreementFormatId']"
            @change="setAgreementFilters($event)"
          >
            <template v-slot:first>
              <b-form-select-option :value="null">-- Выберите релиз --</b-form-select-option>
            </template>
          </b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Период для поиска сеансов:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.showsDateStart']} ${errors['filter.showsDateEnd']}`"
      :state="states['filter.showsDateStart'] || states['filter.showsDateEnd']"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="filter.showsDateStart"
        :state="states['filter.showsDateStart']"
        :disabled="loading"
        @change="setFilter({ field: 'showsDateStart', value: $event })"
      ></date-popover>
      <date-popover
        size="sm"
        :value="filter.showsDateEnd"
        :state="states['filter.showsDateEnd']"
        :disabled="loading"
        @change="setFilter({ field: 'showsDateEnd', value: $event })"
      ></date-popover>
    </b-form-group>

    <b-form-group
      class="mt-2"
      :disabled="loading"
      :invalid-feedback="errors['params.aggregate']"
    >
      <b-form-checkbox
        :checked="!!params.aggregate"
        :disabled="loading"
        :state="states['params.aggregate']"
        @change="setParam({ field: 'aggregate', value: $event ? 1 : 0 });"
      >Общий отчет</b-form-checkbox>
    </b-form-group>

    <div class="mt-4">
      <b-button
        variant="info"
        size="sm"
        :disabled="loading"
        @click="fetchReport({ format: 'html' })"
      >Просмотр</b-button>
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
        @click="printReport()"
      ><icon icon="fa-print"></icon> Печать</b-button>
      <b-dropdown
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
      >
        <template #button-content>
          <icon icon="fa-save"></icon>
        </template>
        <b-dropdown-item
          v-for="format in formats"
          :key="format.value"
          @click="fetchReport({ format: format.value })"
        >{{format.text}}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DictionaryProvider from '../../../providers/DictionaryProvider';
import DatePopover from '../../../components/fields/DatePopover.vue';

import datetime from '../../../utils/datetime';

export default {
  components: {
    DictionaryProvider,
    DatePopover,
  },
  data() {
    return {
      format: 'xlsx',
      formats: [
        { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'XLSX' },
      ],
    };
  },
  computed: {
    ...mapState(['cinemaId']),

    ...mapState('reports/distributor5', {
      params: (state) => state.params,
      filter: (state) => state.filter,
      loading: (state) => state.loading,
      errors: (state) => state.errors,
      states: (state) => state.states,
    }),

    selectedAgreement() {
      const { distributorAgreementId, distributorAgreementFormatId } = this.filter;

      return distributorAgreementFormatId ? `${distributorAgreementId}__${distributorAgreementFormatId}` : distributorAgreementId;
    },
  },
  created() {
    const date = datetime.convertDateToDbFormat(new Date());

    this.setParam({ field: 'cinemaId', value: this.cinemaId });
    this.setFilter({ field: 'dateStart', value: date });
    this.setFilter({ field: 'dateEnd', value: date });
    this.setFilter({ field: 'distributorAgreementId', value: null });
    this.setFilter({ field: 'distributorAgreementFormatId', value: null });
    this.setFilter({ field: 'showsDateStart', value: null });
    this.setFilter({ field: 'showsDateEnd', value: null });
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      setParam: 'reports/distributor5/setParam',
      setFilter: 'reports/distributor5/setFilter',
      fetchReport: 'reports/distributor5/fetchReport',
      reset: 'reports/distributor5/reset',
    }),

    convertAgreements(agreements) {
      if (!agreements) {
        return [];
      }

      return agreements.reduce((items, item) => {
        if (item.multiformat || item.formats.length === 1) {
          const formats = item.formats.map((f) => f.name).join(', ');

          return [
            ...items,
            {
              value: item.id,
              text: `${item.movie.name} (${formats})`,
            },
          ];
        }

        return [
          ...items,
          ...item.formats.map((format) => ({
            value: `${item.id}__${format.id}`,
            text: `${item.movie.name} (${format.name})`,
          })),
        ];
      }, []);
    },

    setAgreementFilters(value) {
      if (!value) {
        this.setFilter({ field: 'distributorAgreementId', value: null });
        this.setFilter({ field: 'distributorAgreementFormatId', value: null });
        return;
      }

      const valueAsString = value.toString();

      if (valueAsString.indexOf('__') !== -1) {
        const [distributorAgreementId, distributorAgreementFormatId] = valueAsString.split('__');

        this.setFilter({ field: 'distributorAgreementId', value: distributorAgreementId });
        this.setFilter({ field: 'distributorAgreementFormatId', value: distributorAgreementFormatId });
      } else {
        this.setFilter({ field: 'distributorAgreementId', value });
        this.setFilter({ field: 'distributorAgreementFormatId', value: null });
      }
    },

    async printReport() {
      const [err, data] = await this.fetchReport({ format: 'html' });

      if (err || !data) {
        return;
      }

      this.$nextTick(() => {
        this.$htmlToPaper('htmlReport');
      });
    },
  },
};
</script>
