<template>
  <div v-show="item">
    <h2 class="header">Шаблон {{ item.name }}</h2>
    <div class="bar">
      <button v-on:click="save">Save</button>
    </div>
    <EmailEditor
        ref="emailEditor"
        v-on:load="editorLoaded"
    />
  </div>
</template>

<script>
import { EmailEditor } from 'vue-email-editor/src/components';
import http from '@/api/http';
import { API_VERSION } from '@/config';

export default {
  components: {
    EmailEditor,
  },
  data() {
    return {
      item: {},
    };
  },
  methods: {
    async editorLoaded() {
      const response = await http.get(`/${API_VERSION}/notification_templates/${this.$route.params.templateId}`);
      this.$data.item = response.data;
      if (response.data.content_raw) {
        this.$refs.emailEditor.editor.loadDesign(JSON.parse(response.data.content_raw));
      }
    },
    async save() {
      this.$refs.emailEditor.editor.exportHtml((data) => {
        http.put(`/${API_VERSION}/notification_templates/${this.$route.params.templateId}`, {
          id: this.$data.item.id,
          name: this.$data.item.name,
          title: this.$data.item.title,
          content: data.html,
          content_raw: data.design,
        });
        alert('Saved!');
      });
    },
  },
};
</script>

<style>
.unlayer-editor iframe {
  min-height: 768px !important;
}

.bar {
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
}

.header {
  padding-left: 10px;
  padding-top: 10px;
}
</style>
