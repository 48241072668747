<template>
  <div class="table-container notificationCampaigns">
    <collection-provider
      collection="notificationCampaigns"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <dictionary-provider name="notificationTags">
          <template>
            <datatable
                v-bind="{ ...data, ...actions }"
                :fields="datatable.fields"
                :panel-actions="datatable.panelActions"
                :item-actions="datatable.itemActions"
                :checked="datatable.checked"
                :permissions="$user.permissions"
                @change-checked="datatable.checked = $event"
                @panel-action="performPanelAction($event)"
                @item-action="performItemAction($event)"
            >
              <template #cell(from_name)="data">
                <div>{{data.item.fromName}}</div>
                <div
                    v-if="data.item.fromEmail"
                    class="text-muted mt-2"
                >
                  {{data.item.fromEmail}}
                </div>
              </template>
              <template #cell(schedule)="data">
                <div v-if="data.item.schedule">
                  {{ data.item.scheduleHumanReadable }}
                  <div v-if="data.item.isScheduled">Включен</div>
                  <div v-else>Выключен</div>
                </div>
              </template>
              <template #cell(subject)="data">
                <div v-if="data.item.type === 'firebase'">
                  {{ data.item.subject }}
                  <div class="text-muted mt-2">
                    {{ data.item.body}}
                  </div>
                </div>
                <div v-else>
                  <RouterLink :to="{ name: 'notificationTemplate', params: { templateId: data.item.templateId }}">{{ getTemplateName(data.item.templateId)  }}</RouterLink>
                </div>
              </template>

              <template #cell(selectionId)="data">
                <div v-if="data.item.selections.length > 0">
                  <b>Выборки:</b> {{ $array.convertToText(data.item.selections) }}
                  <div v-if="data.item.tags.length > 0">
                    <br>
                  </div>
                </div>
                <div v-if="data.item.tags.length > 0">
                  <b>Тэги:</b> {{ $array.convertToText(data.item.tags) }}
                </div>
              </template>

              <template #cell(status)="data">
                <div>{{data.item.status}}</div>
                <div
                    v-if="data.item.statusDescription"
                    class="text-muted mt-2"
                >
                  {{data.item.statusDescription}}
                </div>
              </template>
              <template #cell(updatedAt)="data">
                <div><span class="text-muted mt-2">created:&nbsp;</span>{{$datetime.formatDateTime(data.item.createdAt)}}</div>
                <div><span class="text-muted mt-2">updated:&nbsp;</span>{{$datetime.formatDateTime(data.item.updatedAt)}}</div>
                <div v-if="data.item.startDate"><span class="text-muted mt-2">start:&nbsp;</span>{{$datetime.formatDateTime(data.item.startDate)}}</div>
              </template>
            </datatable>
          </template>
        </dictionary-provider>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.entity.show"
      create-title="Создание кампании"
      edit-title="Редактирование кампании"
      collection="notificationCampaigns"
      :id="modals.entity.id"
      @close="modals.entity.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <entity-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></entity-form>
      </template>
    </modal-model-form>

    <b-modal
        :visible="true"
        v-if="modals.test.show"
        @hidden="modals.test.show = false"
        hide-footer
    >
      <form
          class="filter d-flex"
          @submit.prevent="testSend"
      >
        <b-container fluid class="p-0">
          <b-row>
            <b-col>
              <b-form-group
                  label="Тестовый email:"
              >
                  <input type="email" autocomplete="false" v-model="testEmail"/>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col align="right">
              <b-button
                  class="mr-2"
                  variant="info"
                  size="sm"
                  type="submit"
              >Отправить
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { API_VERSION } from '@/config';
import { convertError } from '@/api/utilities';
import http from '@/api/http';
import { confirmDialog } from '../../../utils/dialogs';

import fields from './_index.fields';
import itemActions from './_index.itemActions';
import panelActions from './_index.panelActions';

import Datatable from '../../../components/datatable/Datatable.vue';

import DictionaryProvider from '../../../providers/DictionaryProvider';
import CollectionProvider from '../../../providers/CollectionProvider';
import ModalModelForm from '../../../components/modal/ModalModelForm.vue';
import EntityForm from './EntityForm.vue';

export default {
  components: {
    DictionaryProvider,
    CollectionProvider,
    ModalModelForm,
    Datatable,
    EntityForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        itemActions,
        panelActions,
      },
      testEmail: '',
      modals: {
        entity: {
          show: false,
          id: 0,
        },
        test: {
          show: false,
          id: 0,
        },
      },
    };
  },
  computed: {
    ...mapState('dictionaries/notificationTemplates', {
      notificationTemplates: (state) => state.items,
    }),
  },
  async created() {
    await this.$store.dispatch('dictionaries/notificationTemplates/fetchItems');
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateCampaignForm() {
      this.modals.entity.id = '';
      this.modals.entity.show = true;
      this.modals.test.show = false;
    },

    async removeCheckedCampaigns() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    openEditNotificationsCampaignForm(id) {
      this.modals.entity.id = id;
      this.modals.entity.show = true;
      this.modals.test.show = false;
    },

    openTestForm(id) {
      this.modals.test.id = id;
      this.modals.entity.show = false;
      this.modals.test.show = true;
    },

    async removeCampaign(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },

    async startCampaign(id) {
      if (await confirmDialog('Вы уверены?')) {
        try {
          const response = await http.post(`/${API_VERSION}/notification_campaigns/${id}/start`, { }, {
            headers: { 'Content-Type': 'multipart/form-data' },
          });

          this.$refs.collection.fetchItems();

          return [null, response.data || {}];
        } catch (error) {
          return convertError(error);
        }
      }

      return [{}];
    },

    async exportCampaign(id) {
      try {
        const response = await http.get(`/${API_VERSION}/notification_campaigns/${id}/export`);
        // Create a blob
        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Create a link to download it
        const pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', 'export.csv');
        pom.style.visibility = 'hidden';
        document.body.appendChild(pom);
        pom.click();
        document.body.removeChild(pom);
      } catch (error) {
        return convertError(error);
      }

      return [{}];
    },

    async testSend() {
      if (await confirmDialog('Вы уверены?')) {
        try {
          const response = await http.post(`/${API_VERSION}/notification_campaigns/${this.modals.test.id}/test`, {
            email: this.testEmail,
          });

          this.modals.test.show = false;

          return [null, response.data || {}];
        } catch (error) {
          return convertError(error);
        }
      }

      return [{}];
    },

    getTemplateName(templateId) {
      const template = this.notificationTemplates.find(({ id }) => id == templateId);
      return template ? template.name : '';
    },

    async exportCampaignReport(id) {
      try {
        const response = await http.get(`/${API_VERSION}/notification_campaigns/${id}/export-report`);
        // Create a blob
        const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);

        // Create a link to download it
        const pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', 'export.csv');
        pom.style.visibility = 'hidden';
        document.body.appendChild(pom);
        pom.click();
        document.body.removeChild(pom);
      } catch (error) {
        return convertError(error);
      }

      return [{}];
    },
  },
};
</script>
