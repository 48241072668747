<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-form-group
          label="Название:"
          :invalid-feedback="errors.name"
          :state="states.name"
        >
          <b-form-input
            type="text"
            size="sm"
            autofocus
            :value="item.name"
            :state="states.name"
            :disabled="loading"
            @change="updateValue('name', $event)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="Описание:"
          :invalid-feedback="errors.description"
          :state="states.description"
        >
          <b-form-textarea
            type="text"
            size="sm"
            :value="item.description"
            :state="states.description"
            :disabled="loading"
            @change="updateValue('description', $event)"
          ></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
            label="Файл:"
            :invalid-feedback="errors.filePath"
            :state="states.filePath"
        >
          <b-row class="p-0">
            <b-col>
              <file-uploader
                  ref="fileUploader"
                  :accept="mimeTypes.join(',')"
                  :disabled="loading"
                  @change="uploadFile($event)"
              ></file-uploader>
            </b-col>

            <b-col>
              <div
                  v-if="!item.filePath && !$fileManager2.files[fileId]"
                  class="text-center"
              >
              </div>
              <div
                  v-if="!item.filePath && $fileManager2.files[fileId]"
                  class="loader"
              >
                <template v-if="!$fileManager2.files[fileId].error"><span class="mr-2">{{ $fileManager2.files[fileId].progress }}%</span> <b-spinner variant="primary"></b-spinner></template>
                <template v-else>Ошибка при загрузке файла!</template>
              </div>
            </b-col>
          </b-row>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import FileUploader from '../../components/fields/FileUploader.vue';

export default {
  components: {
    FileUploader,
  },
  data() {
    return {
      fileId: -1,
      mimeTypes: [
        'application/pdf', // pdf
        'application/vnd.ms-excel', // xls
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // xlsx
        'application/msword', // doc
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // docx
        'text/csv', // csv
      ],
    };
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  methods: {
    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    async uploadFile(files) {
      this.updateValue('filePath', '');
      this.updateValue('fileUrl', '');

      this.$fileManager2.upload(files[0]).then((data) => {
        if (!data) {
          return;
        }

        this.updateValue('filePath', data.path);
        this.updateValue('fileUrl', data.url);

        this.fileId = -1;
      });

      this.fileId = this.$fileManager2.getFileIndex(files[0]);
    },
  },
};
</script>
