<template>
  <div class="sidebar p-2">
    <b-form-group
      label="Период дат:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.dateStart']} ${errors['filter.dateEnd']}`"
      :state="states['filter.dateStart'] && states['filter.dateEnd']"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="filter.dateStart"
        :state="states['filter.dateStart']"
        :disabled="loading"
        @change="updateFilters('dateStart', $event), onChange($event, 'report-attendance-by-halls/dateStart')"
      ></date-popover>
      <date-popover
        size="sm"
        :value="filter.dateEnd"
        :state="states['filter.dateEnd']"
        :disabled="loading"
        @change="updateFilters('dateEnd', $event), onChange($event, 'report-attendance-by-halls/dateEnd')"
      ></date-popover>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Период времени:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.timeStart']} ${errors['filter.timeEnd']}`"
    >
      <b-input
        size="sm"
        class="mb-2"
        type="time"
        :value="filter.timeStart"
        :state="states['filter.timeStart']"
        :disabled="loading"
        @change="updateFilters('timeStart', $event)"
      ></b-input>
      <b-input
        size="sm"
        type="time"
        :value="filter.timeEnd"
        :state="states['filter.timeEnd']"
        :disabled="loading"
        @change="updateFilters('timeEnd', $event)"
      ></b-input>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Учет типа суток:"
      :disabled="loading"
      :invalid-feedback="errors['params.dayType']"
    >
      <b-form-radio-group
        name="day-type"
        :state="states['params.dayType']"
        :checked="params.dayType"
        :disabled="loading"
        @change="setParam({ field: 'dayType', value: $event })"
      >
        <b-form-radio value="business">Бизнес-сутки</b-form-radio>
        <b-form-radio value="calendar">Календарные</b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-form-group
      label="Релизы:"
      :invalid-feedback="errors['filter.releaseIds']"
      :state="states['filter.releaseIds']"
    >
      <dictionary-provider
        name="showsReleases"
        :filter="showsReleasesFilter"
        :disable-fetching="!filter.dateStart || !filter.dateEnd"
      >
        <template #default="{ items, loading: dictionaryLoading }">
          <toggle-list
            :options="convertReleases(items)"
            :value="convertToDictionaryFromIds(filter.releaseIds, items)"
            :searchable="true"
            :disabled="loading || dictionaryLoading || (!filter.dateStart || !filter.dateEnd)"
            @change="setFilter({ field: 'releaseIds', value: convertFromDictionaryToIds($event) })"
          ></toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <div class="mt-2">
      <b-button
        variant="info"
        size="sm"
        :disabled="loading"
        @click="fetchReport({ format: 'html' })"
      >Просмотр</b-button>
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
        @click="printReport()"
      ><icon icon="fa-print"></icon> Печать</b-button>
      <b-dropdown
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
      >
        <template #button-content>
          <icon icon="fa-save"></icon>
        </template>
        <b-dropdown-item
          v-for="format in formats"
          :key="format.value"
          @click="fetchReport({ format: format.value })"
        >{{format.text}}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DictionaryProvider from '../../../providers/DictionaryProvider';
import DatePopover from '../../../components/fields/DatePopover.vue';
import ToggleList from '../../../components/fields/ToggleList.vue';

export default {
  components: {
    DictionaryProvider,
    DatePopover,
    ToggleList,
  },
  data() {
    return {
      format: 'xlsx',
      formats: [
        { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'XLSX' },
      ],
    };
  },
  computed: {
    ...mapState(['cinemaId']),

    ...mapState('reports/attendanceByHalls', {
      params: (state) => state.params,
      filter: (state) => state.filter,
      loading: (state) => state.loading,
      errors: (state) => state.errors,
      states: (state) => state.states,
    }),

    showsReleasesFilter() {
      const filter = {
        cinemaId: this.params.cinemaId,
      };

      if (this.params.dayType === 'calendar') {
        filter.calendarDateStart = this.filter.dateStart;
        filter.calendarDateEnd = this.filter.dateEnd;
      } else {
        filter.dateStart = this.filter.dateStart;
        filter.dateEnd = this.filter.dateEnd;
      }

      if (this.filter.timeStart) {
        filter.businessTimeStart = this.filter.timeStart;
      }

      if (this.filter.timeEnd) {
        filter.businessTimeEnd = this.filter.timeEnd;
      }
      return filter;
    },
  },
  created() {
    this.setParam({ field: 'cinemaId', value: this.cinemaId });
    this.setParam({ field: 'dayType', value: 'business' });
    this.setFilter({ field: 'dateStart', value: window.localStorage.getItem('report-attendance-by-halls/dateStart') });
    this.setFilter({ field: 'dateEnd', value: window.localStorage.getItem('report-attendance-by-halls/dateEnd') });
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      setParam: 'reports/attendanceByHalls/setParam',
      setFilter: 'reports/attendanceByHalls/setFilter',
      fetchReport: 'reports/attendanceByHalls/fetchReport',
      reset: 'reports/attendanceByHalls/reset',
    }),

    onChange(value, name) {
      window.localStorage.setItem(name, value);
    },

    convertReleases(releases) {
      if (!releases) {
        return [];
      }

      return releases.map((release) => ({
        id: release.id,
        name: release?.format?.name ? `${release?.movie?.name}, ${release?.format?.name}` : release?.movie?.name,
      }));
    },

    convertToDictionaryFromIds(ids, items) {
      if (!ids || !items) {
        return [];
      }

      return ids.map((id) => items.find((item) => item.id === id));
    },

    convertFromDictionaryToIds(items) {
      if (!items) {
        return [];
      }

      return items.map((item) => item.id);
    },

    updateFilters(fieldName, $event) {
      this.setFilter({ field: 'releaseIds', value: [] });
      this.setFilter({ field: fieldName, value: $event });
      this.setFilter({ field: 'movieIds', value: [] });
    },

    async printReport() {
      const [err, data] = await this.fetchReport({ format: 'html' });

      if (err || !data) {
        return;
      }

      this.$nextTick(() => {
        this.$htmlToPaper('htmlReport');
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 279px;
  min-width: 279px;
}
</style>
