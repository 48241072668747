<template>
  <b-nav class="cinema-tabs" pills>
    <b-nav-item
      v-if="$user.can('cinemas.view')"
      :active="tab === 'cinema'"
      @click="openRoute(`/administration/cinemas/${cinemaId}/`)"
    >Информация</b-nav-item>
    <b-nav-item
      v-if="$user.can('halls.view')"
      :active="tab === 'halls'"
      @click="openRoute(`/administration/cinemas/${cinemaId}/halls/`)"
    >Залы</b-nav-item>
    <b-nav-item
      v-if="$user.can('employees.view')"
      :active="tab === 'employees'"
      @click="openRoute(`/administration/cinemas/${cinemaId}/employees/`)"
    >Сотрудники</b-nav-item>
    <b-nav-item
      v-if="$user.can('cashier_devices.view')"
      :active="tab === 'cashier-devices'"
      @click="openRoute(`/administration/cinemas/${cinemaId}/cashier-devices/`)"
    >Кассовые устройства</b-nav-item>
    <b-nav-item
      v-if="$user.can('cashier_places.view')"
      :active="tab === 'cashier-places'"
      @click="openRoute(`/administration/cinemas/${cinemaId}/cashier-places/`)"
    >Кассовые места</b-nav-item>
    <b-nav-item
      v-if="$user.can('kiosks.view')"
      :active="tab === 'kiosks'"
      @click="openRoute(`/administration/cinemas/${cinemaId}/kiosks/`)"
    >Киоски</b-nav-item>
    <b-nav-item
      v-if="$user.can('documents.view')"
      :active="tab === 'documents'"
      @click="openRoute(`/administration/cinemas/${cinemaId}/documents/`)"
    >Документы</b-nav-item>
    <b-nav-item
      v-if="$user.can('settings.view')"
      :active="tab === 'settings'"
      @click="openRoute(`/administration/cinemas/${cinemaId}/settings/`)"
    >Настройки</b-nav-item>
    <b-nav-item
      v-if="$user.can('social_networks.view')"
      :active="tab === 'social-networks'"
      @click="openRoute(`/administration/cinemas/${cinemaId}/social-networks/`)"
    >Социальные сети</b-nav-item>
    <b-nav-item
      v-if="$user.can('cinema_photos.view')"
      :active="tab === 'cinema-photos'"
      @click="openRoute(`/administration/cinemas/${cinemaId}/cinema-photos/`)"
    >Фото</b-nav-item>
    <b-nav-item
      v-if="$user.can('cinema_documents.view')"
      :active="tab === 'cinema-documents'"
      @click="openRoute(`/administration/cinemas/${cinemaId}/cinema-documents/`)"
    >Правовая информация</b-nav-item>
  </b-nav>
</template>

<script>
export default {
  props: {
    cinemaId: {
      default: null,
    },
  },
  computed: {
    tab() {
      const tabs = [
        'cashier-devices',
        'cashier-places',
        'cinema-photos',
        'cinema-documents',
        'cinema',
        'documents',
        'employees',
        'halls',
        'kiosks',
        'settings',
        'social-networks',
      ];

      const tab = this.$route.params.tab || 'cinema';

      return tabs.includes(tab) ? tab : 'cinema';
    },
  },
  methods: {
    openRoute(path) {
      this.$router.push(path).catch((err) => {});
    },
  },
};
</script>

<style scoped>
.cinema-tabs >>> .nav-link {
  padding: 0.25rem 0.5rem;
}
</style>
