<template>
  <div class="p-2">
    <b-form-group
      label="Период:"
      :disabled="loading"
      :invalid-feedback="`${errors['filter.sessionDateStart']} ${errors['filter.sessionDateEnd']}`"
    >
      <date-popover
        size="sm"
        class="mb-2"
        :value="filter.sessionDateStart"
        :state="states['filter.sessionDateStart']"
        :disabled="loading"
        @change="setFilter({ field: 'sessionDateStart', value: $event }), setFilter({ field: 'employeeId', value: null }), setFilter({ field: 'employeeSessionId', value: null })"
      ></date-popover>
      <date-popover
        size="sm"
        :value="filter.sessionDateEnd"
        :state="states['filter.sessionDateEnd']"
        :disabled="loading"
        @change="setFilter({ field: 'sessionDateEnd', value: $event }), setFilter({ field: 'employeeId', value: null }), setFilter({ field: 'employeeSessionId', value: null })"
      ></date-popover>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Сотрудник:"
      :disabled="loading || !params.cinemaId"
      :invalid-feedback="errors['filter.employeeId']"
    >
      <dictionary-provider
        name="employees"
        :filter="{ cinemaId: params.cinemaId, sessionDateStart: filter.sessionDateStart, sessionDateEnd: filter.sessionDateEnd }"
        :disable-fetching="!params.cinemaId"
      >
        <template #default="{ items: employees, loading: employeesLoading }">
          <b-form-select
            size="sm"
            :options="$array.convertToOptions(employees, null)"
            :value="filter.employeeId"
            :disabled="employeesLoading || loading"
            :state="states['filter.employeeId']"
            @change="setFilter({ field: 'employeeId', value: $event }), setFilter({ field: 'employeeSessionId', value: null })"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>-- Выберите сотрудника --</b-form-select-option>
            </template>
          </b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Смена:"
      :disabled="loading || !filter.employeeId"
      :invalid-feedback="errors['filter.employeeSessionId']"
    >
      <dictionary-provider
        name="employeeSessions"
        :filter="{ cinemaId: params.cinemaId, employeeId: filter.employeeId, sessionDateStart: filter.sessionDateStart, sessionDateEnd: filter.sessionDateEnd }"
        :disable-fetching="!params.cinemaId || !filter.employeeId"
      >
        <template #default="{ items: employeeSessions, loading: employeeSessionsLoading }">
          <b-form-select
            size="sm"
            :options="convertEmployeeSessions(employeeSessions, null)"
            :value="filter.employeeSessionId"
            :disabled="employeeSessionsLoading || loading"
            :state="states['filter.employeeSessionId']"
            @change="setFilter({ field: 'employeeSessionId', value: $event })"
          >
            <template v-slot:first>
              <b-form-select-option :value="null" disabled>-- Выберите смену --</b-form-select-option>
            </template>
          </b-form-select>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      class="mt-2"
      label="Включить в отчет:"
      :disabled="loading"
    >
      <b-form-checkbox
        :checked="!!filter.includeTickets"
        :disabled="loading"

        :state="states['filter.includeTickets']"
        @change="setFilter({ field: 'includeTickets', value: $event ? 1 : 0 })"
      >Билеты</b-form-checkbox>

      <b-form-checkbox
        :checked="!!filter.includeProducts"
        :disabled="loading"
        :state="states['filter.includeProducts']"
        @change="setFilter({ field: 'includeProducts', value: $event ? 1 : 0 })"
      >Товары</b-form-checkbox>

      <b-form-checkbox
        :checked="!!filter.includeCertificates"
        :disabled="loading"
        :state="states['filter.includeCertificates']"
        @change="setFilter({ field: 'includeCertificates', value: $event ? 1 : 0 })"
      >Сертификаты</b-form-checkbox>

      <b-form-checkbox
        :checked="!!filter.includeBarItems"
        :disabled="loading"
        :state="states['filter.includeBarItems']"
        @change="setFilter({ field: 'includeBarItems', value: $event ? 1 : 0 })"
      >Бар</b-form-checkbox>
    </b-form-group>

    <div class="mt-4">
      <b-button
        variant="info"
        size="sm"
        :disabled="loading"
        @click="fetchReport({ format: 'html' })"
      >Просмотр</b-button>
      <b-button
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
        @click="printReport()"
      ><icon icon="fa-print"></icon> Печать</b-button>
      <b-dropdown
        variant="outline-info"
        size="sm"
        class="ml-2"
        :disabled="loading"
      >
        <template #button-content>
          <icon icon="fa-save"></icon>
        </template>
        <b-dropdown-item
          v-for="format in formats"
          :key="format.value"
          @click="fetchReport({ format: format.value })"
        >{{format.text}}</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import DictionaryProvider from '../../../providers/DictionaryProvider';
import DatePopover from '../../../components/fields/DatePopover.vue';

import datetime from '../../../utils/datetime';

export default {
  components: {
    DictionaryProvider,
    DatePopover,
  },
  data() {
    return {
      format: 'xlsx',
      formats: [
        { value: 'pdf', text: 'PDF' },
        { value: 'xlsx', text: 'XLSX' },
      ],
    };
  },
  computed: {
    ...mapState(['cinemaId']),

    ...mapState('reports/cashier', {
      params: (state) => state.params,
      filter: (state) => state.filter,
      loading: (state) => state.loading,
      errors: (state) => state.errors,
      states: (state) => state.states,
    }),
  },
  created() {
    const date = datetime.convertDateToDbFormat(new Date());

    this.setParam({ field: 'cinemaId', value: this.cinemaId });
    this.setFilter({ field: 'employeeId', value: null });
    this.setFilter({ field: 'employeeSessionId', value: null });
    this.setFilter({ field: 'sessionDateStart', value: date });
    this.setFilter({ field: 'sessionDateEnd', value: date });
    this.setFilter({ field: 'includeTickets', value: 0 });
    this.setFilter({ field: 'includeProducts', value: 0 });
    this.setFilter({ field: 'includeCertificates', value: 0 });
    this.setFilter({ field: 'includeBarItems', value: 0 });
  },
  destroyed() {
    this.reset();
  },
  methods: {
    ...mapActions({
      setParam: 'reports/cashier/setParam',
      setFilter: 'reports/cashier/setFilter',
      fetchReport: 'reports/cashier/fetchReport',
      reset: 'reports/cashier/reset',
    }),

    convertEmployeeSessions(sessions) {
      return sessions.map((session) => {
        const endedAt = session.endedAt ? datetime.formatDateTime(session.endedAt) : 'не закрыта';

        return {
          value: session.id,
          text: `${datetime.formatDateTime(session.startedAt)} - ${endedAt}`,
        };
      });
    },

    async printReport() {
      const [err, data] = await this.fetchReport({ format: 'html' });

      if (err || !data) {
        return;
      }

      this.$nextTick(() => {
        this.$htmlToPaper('htmlReport');
      });
    },
  },
};
</script>
