<template>
  <b-container fluid class="p-0">
    <b-form-group
      label="Наименование:"
      :invalid-feedback="errors.name"
      :state="states.name"
    >
      <b-form-input
        type="text"
        autofocus
        size="sm"
        :value="item.name"
        :state="states.name"
        :disabled="loading"
        @change="updateValue('name', $event)"
      ></b-form-input>
    </b-form-group>

    <b-form-group
      label="Описание:"
      :invalid-feedback="errors.description"
      :state="states.description"
    >
      <b-form-input
        size="sm"
        :value="item.description"
        :state="states.description"
        :disabled="loading"
        @change="updateValue('description', $event)"
      ></b-form-input>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Период действия акции</div>
    </div>

    <b-form-group
      label="Дата и время начала и окончания сеансов акции:"
      :invalid-feedback="errors.dateEnd || errors.dateStart"
      :state="states.dateEnd || states.dateStart"
    >
      <date-time-interval
        :value="{from: item.dateStart, to: item.dateEnd}"
        :disabled="loading"
        @selected="updateValue('dateStart', $event.from), updateValue('dateEnd', $event.to)">
      </date-time-interval>
    </b-form-group>

    <b-form-group
      label="Время действия:"
      :invalid-feedback="errors.timeStart || errors.timeEnd"
      :state="states.timeStart || states.timeEnd"
    >
      <time-interval
        :value="{from: item.timeStart, to: item.timeEnd}"
        :disabled="loading"
        @change="updateValue('timeStart', $event.from), updateValue('timeEnd', $event.to)"
      ></time-interval>
    </b-form-group>

    <b-form-group
      label="Дата начала и окончания продаж:"
      :invalid-feedback="errors.saleDateEnd || errors.saleDateStart"
      :state="states.saleDateEnd || states.saleDateStart"
    >
      <date-interval
        :value="{from: item.saleDateStart, to: item.saleDateEnd}"
        :disabled="loading"
        @selected="updateValue('saleDateStart', $event.from), updateValue('saleDateEnd', $event.to)">
      </date-interval>
    </b-form-group>

    <b-form-group
      label="Дни недели:"
      :invalid-feedback="errors.days"
      :state="states.days"
    >
      <toggle-list
        placeholder="Все"
        :options="days"
        :value="item.days"
        :disabled="loading"
        @change="updateValue('days', $event)"
      ></toggle-list>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Место действия акции</div>
    </div>

    <b-form-group
      label="Кинотеатры:"
      :invalid-feedback="errors.cinemas"
      :state="states.cinemas"
    >
      <toggle-list
        placeholder="Все"
        :options="convertCinemas($array.union(cinemas, item.cinemas))"
        :value="item.cinemas"
        :disabled="loading || cinemasLoading"
        @change="updateValue('cinemas', $event), updateValue('discounts', [])"
      ></toggle-list>
    </b-form-group>

    <b-form-group
      label="Категории залов:"
      :invalid-feedback="errors.hallCategories"
      :state="states.hallCategories"
    >
      <toggle-list
        placeholder="Все"
        :options="hallCategories"
        :value="item.hallCategories"
        :disabled="loading || hallCategoriesLoading"
        @change="updateValue('hallCategories', $event)"
      ></toggle-list>
    </b-form-group>

    <div class="text-uppercase flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Фильтры фильмов, для которых действует акция</div>
    </div>

    <b-form-group
      label="Релизы:"
      :invalid-feedback="errors.releases"
      :state="states.releases"
    >
      <toggle-list
        placeholder="Все"
        :options="convertReleases(showsReleases)"
        :value="filterReleases(item.releases)"
        :searchable="true"
        :disabled="loading || showsReleasesLoading"
        @change="updateValue('releases', $event)"
      ></toggle-list>
    </b-form-group>

    <b-form-group
      label="Исключенные релизы:"
      :invalid-feedback="errors.exceptedReleases"
      :state="states.exceptedReleases"
    >
      <toggle-list
        placeholder="Все"
        :options="convertReleases(showsReleases)"
        :value="filterReleases(item.exceptedReleases)"
        :searchable="true"
        :disabled="loading || showsReleasesLoading"
        @change="updateValue('exceptedReleases', $event)"
      ></toggle-list>
    </b-form-group>

    <b-form-group
      label="Форматы фильмов:"
      :invalid-feedback="errors.formats"
      :state="states.formats"
    >
      <toggle-list
        placeholder="Все"
        :options="formats"
        :value="item.formats"
        :disabled="loading || formatsLoading"
        @change="updateValue('formats', $event)"
      ></toggle-list>
    </b-form-group>

    <div class="text-uppercase d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Параметры изменения цены</div>
    </div>

    <b-form-group
      label="Скидки, которые не будут применяться:"
      :invalid-feedback="errors.discounts"
      :state="states.discounts"
    >
      <dictionary-provider
        name="discounts"
        :filter="filterCinemasIds"
        :disable-fetching="loading"
      >
        <template #default="{ items, loading: discountsLoading }">
          <toggle-list
            placeholder="Все"
            :options="items"
            :value="convertDiscountsItems(items, item.discounts)"
            :disabled="loading || discountsLoading"
            @change="updateValue('discounts', $event)"
          >
            <template v-if="item.discounts && item.discounts.length" #clear>
              <div
                class="multiselect__clear"
                @click="updateValue('discounts', [])"
              >
                <icon icon="fa-times"></icon>
              </div>
            </template>
          </toggle-list>
        </template>
      </dictionary-provider>
    </b-form-group>

    <b-form-group
      label="Изменение цены:"
      :invalid-feedback="errors.formula"
      :state="states.formula"
    >
      <price-formula
        :value="item.formula"
        :operations="['-', '=', '+']"
        :disabled="loading"
        @change="updateValue('formula', $event)"
      ></price-formula>
    </b-form-group>

    <b-form-group
        label="Минимальная цена:"
        :invalid-feedback="errors.minPrice"
        :state="states.minPrice"
    >
      <b-form-input
          type="text"
          size="sm"
          :value="item.minPrice"
          :state="states.minPrice"
          :disabled="loading"
          @change="updateValue('minPrice', $event)"
      ></b-form-input>
    </b-form-group>

    <color-schemes-formulas
      class="mt-3"
      :color-schemes="colorSchemes"
      :color-scheme-formulas="item.colorSchemes"
      :disabled="loading || colorSchemesLoading"
      :operations="['-', '=', '+']"
      @change="updateValue('colorSchemes', $event)"
    ></color-schemes-formulas>

    <app-groups-formulas
      class="mt-3"
      :app-groups="appGroups"
      :app-group-formulas="item.appGroups"
      :disabled="loading || appGroupsLoading"
      :operations="['-', '=', '+']"
      @change="updateValue('appGroups', $event)"
    ></app-groups-formulas>

    <div class="text-uppercase --border-bottom d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Приоритет акции</div>
    </div>

    <b-form-group
      :invalid-feedback="errors.isPrimary"
      :state="states.isPrimary"
    >
      <b-form-checkbox
        :checked="item.isPrimary"
        :state="states.isPrimary"
        :disabled="loading"
        @change="updateValue('isPrimary', !item.isPrimary)"
      >
        Акция является приоритетной и отменяет действие других акций
      </b-form-checkbox>
    </b-form-group>

    <div class="text-uppercase --border-bottom d-flex justify-content-between pb-2 mb-2 mt-4">
      <div class="align-self-end">Статус</div>
    </div>

    <b-form-group
      :invalid-feedback="errors.isEnabled"
      :state="states.isEnabled"
    >
      <b-form-checkbox
        :checked="item.isEnabled"
        :state="states.isEnabled"
        :disabled="loading"
        @change="updateValue('isEnabled', !item.isEnabled)"
      >
        Активировать акцию
      </b-form-checkbox>
    </b-form-group>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import DateTimeInterval from '../../components/fields/DateTimeInterval.vue';
import TimeInterval from '../../components/fields/TimeInterval.vue';
import PriceFormula from '../../components/fields/PriceFormula.vue';
import ToggleList from '../../components/fields/ToggleList.vue';
import ColorSchemesFormulas from '../../components/special/ColorSchemesFormulas.vue';
import DateInterval from '../../components/fields/DateInterval.vue';
import AppGroupsFormulas from '../../components/special/AppGroupsFormulas.vue';
import DictionaryProvider from '../../providers/DictionaryProvider';

export default {
  components: {
    DateInterval,
    DateTimeInterval,
    TimeInterval,
    PriceFormula,
    ToggleList,
    ColorSchemesFormulas,
    AppGroupsFormulas,
    DictionaryProvider,
  },
  props: {
    loading: {
      default: false,
    },
    errors: {
      default: () => ({}),
    },
    states: {
      default: () => ({}),
    },
    item: {
      default: () => ({}),
    },
  },
  data() {
    return {
      days: [
        { id: 1, name: 'ПН' },
        { id: 2, name: 'ВТ' },
        { id: 3, name: 'СР' },
        { id: 4, name: 'ЧТ' },
        { id: 5, name: 'ПТ' },
        { id: 6, name: 'СБ' },
        { id: 7, name: 'ВС' },
      ],
    };
  },
  computed: {
    ...mapState('dictionaries/showsReleases', {
      showsReleases: (state) => state.items,
      showsReleasesLoading: (state) => state.loading,
    }),
    ...mapState('dictionaries/cinemas', {
      cinemas: (state) => state.items,
      cinemasLoading: (state) => state.loading,
    }),
    ...mapState('dictionaries/hallCategories', {
      hallCategories: (state) => state.items,
      hallCategoriesLoading: (state) => state.loading,
    }),
    ...mapState('dictionaries/appGroups', {
      appGroups: (state) => state.items,
      appGroupsLoading: (state) => state.loading,
    }),
    ...mapState('dictionaries/colorSchemes', {
      colorSchemes: (state) => state.items,
      colorSchemesLoading: (state) => state.loading,
    }),
    ...mapState('dictionaries/formats2', {
      formats: (state) => state.items,
      formatsLoading: (state) => state.loading,
    }),

    filterCinemasIds() {
      return !this.loading && Object.keys(this.item).length && {
        cinemaIds: this.item.cinemas?.map((cinema) => cinema.id),
      };
    },
  },
  created() {
    this.fetchShowsReleases();
    this.fetchCinemas();
    this.fetchGroups();
    this.fetchColorSchemes();
    this.fetchFormats();
    this.fetchHallCategories();
  },
  methods: {
    ...mapActions('dictionaries/showsReleases', {
      fetchShowsReleases: 'fetchItems',
    }),
    ...mapActions('dictionaries/cinemas', {
      fetchCinemas: 'fetchItems',
    }),
    ...mapActions('dictionaries/appGroups', {
      fetchGroups: 'fetchItems',
    }),
    ...mapActions('dictionaries/colorSchemes', {
      fetchColorSchemes: 'fetchItems',
    }),
    ...mapActions('dictionaries/discounts', {
      fetchDiscounts: 'fetchItems',
    }),
    ...mapActions('dictionaries/formats2', {
      fetchFormats: 'fetchItems',
    }),
    ...mapActions('dictionaries/hallCategories', {
      fetchHallCategories: 'fetchItems',
    }),

    updateValue(field, value) {
      this.$emit('change', {
        field,
        value,
      });
    },

    convertReleases(releases) {
      if (!releases) {
        return [];
      }

      return releases.map((release) => ({
        id: release?.id,
        name: release?.format?.name ? `${release?.movie?.name}, ${release?.format?.name}` : release?.movie?.name,
      }));
    },

    filterReleases(releases) {
      if (!releases) {
        return [];
      }

      const releaseIds = this.showsReleases.map((release) => release.id);

      return releases.filter((release) => releaseIds.includes(release.id));
    },

    convertCinemas(cinemas) {
      if (!cinemas) {
        return [];
      }

      return cinemas.map((cinema) => ({
        id: cinema.id,
        name: `${cinema?.name} ${cinema?.city?.name}`,
      }));
    },

    convertDiscountsItems(discounts, items) {
      if (!items) {
        return [];
      }

      const discountIds = discounts.map((item) => item.id);

      return items.filter((item) => discountIds.includes(item.id));
    },
  },
};
</script>
